// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"
export const teamstablesSlice = createSlice({
  name: "tagstables",
  initialState: {
    data: [],
    loader: false,
    isLoading: false,
    employeeDDl: {},
    selectedManagersDDL: []
  },
  reducers: {
    selectedManagersDDL: (state, action) => {
      state.selectedManagersDDL = action.payload
    },
    getEmployeeDDL: (state, action) => {
      state.employeeDDl = action.payload
    },
    getTeamData: (state, action) => {
      state.data = action.payload
    },
    loadingStatus: (state, action) => {
      state.loader = action.payload
    },
    loadingTeam: (state, action) => {
      state.isLoading = action.payload
    }
  }
})
export const {
  getTeamData,
  loadingStatus,
  getEmployeeDDL,
  loadingTeam,
  selectedManagersDDL
} = teamstablesSlice.actions
export const getData = createAsyncThunk(
  "teamstable/getData",
  async (params, { dispatch }) => {
    dispatch(loadingTeam(true))
    const response = await httpService("GET", "/Teams/teamslist", "", "")
    if (response?.status === 200) {
      dispatch(loadingTeam(false))
      dispatch(getTeamData(response.data.data))
    } else {
      dispatch(getTeamData([]))
      dispatch(loadingTeam(false))
    }
  }
)
export const postData = createAsyncThunk(
  "teamstable/postData",
  async (param, { dispatch, getState }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("POST", "/Teams/addteam", param, "")
    if (response?.status === 200) {
      const updatedData = [
        ...getState()?.TeamData?.data,
        ...response?.data?.data
      ]
      dispatch(getTeamData(updatedData))
      dispatch(loadingStatus(false))
      Toaster("success", "Team Added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const postEdit = createAsyncThunk(
  "teamstable/postEdit",
  async (param, { getState, dispatch }) => {
    dispatch(loadingStatus(true))
    const prevData = [...getState()?.TeamData?.data]
    const response = await httpService("POST", "/Teams/editteam", param, "")
    if (response?.status === 200) {
      dispatch(loadingStatus(false))
      const index = prevData?.findIndex(team => team?.teamId === param?.teamId)
      index > -1 ? prevData.splice(index, 1, response?.data?.data) : ""
      dispatch(getTeamData(prevData))
      Toaster("success", "Team updated successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const getTeamEmployeeDDL = createAsyncThunk(
  "teamstable/EmployeeDDL",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/addprojectmanagers",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(getEmployeeDDL(response?.data?.employeesDDL))
    } else {
      dispatch(getEmployeeDDL({}))
    }
  }
)
export const getSelectedManagersDDL = createAsyncThunk(
  "teamstable/getSelectedManagersDDL",
  async (params, { dispatch }) => {
    const response = await httpService("GET", "/Teams/editteam", "", params)
    if (response?.status === 200) {
      dispatch(selectedManagersDDL(response?.data?.ddlManagers))
    } else {
      dispatch(selectedManagersDDL([]))
    }
  }
)
export const removeExistingTeamManager = createAsyncThunk(
  "projecttable/removeExistingTeamManager",
  async (params, { dispatch, getState }) => {
    const data = getState()?.TeamData?.data.map(item => ({
      ...item,
      managers: item.managers.filter(man => man.userId !== params?.UserId)
    }))
    const response = await httpService(
      "POST",
      "/Teams/RemoveTeamManager",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(getTeamData(data))
      dispatch(loadingStatus(false))
      Toaster("success", response?.data?.message, "Success")
    } else {
      if (response?.data?.response?.data?.message) {
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      } else {
        Toaster("error", response?.data?.message, "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)

export default teamstablesSlice.reducer
