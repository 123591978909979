import moment from "moment"

export const DATE_TIME_FORMATS = {
  LOCAL_TIME: "LT",
  DATE_MONTH: "DD.MM",
  DAY: "DD",
  DAY_ABBR: "ddd",
  MONTH: "MM",
  MONTH_CHAR: "MMMM",
  YEAR: "YYYY",
  Time: "HH:mm:ss",
  Time_Format: "hh:mm",
  FULL_YEAR_AND_SHORT_MONTH: "YYYY-MM",
  FULL_DATE_MONTH_TIME: "YYYYMMDD h:mm:ss a",
  FULL_DAY_MONTH_DATE_AND_YEAR: "dddd, MMMM DD, YYYY",
  FULL_DATE_TIME: "DD-MM-YYYY, h:mm a",
  DATE_FULL_MONTH_AND_YEAR: "MMM YYYY",
  ABBREVIATED_MONTH_AND_DATE: "MMM DD",
  DATE_YEAR_MONTH_DATE_WITH_HYPHEN: "YYYY-MM-DD",
  ISO_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSSZ",
  FULL_DATE: "dddd, MMMM DD, YYYY",
  LAMININ_FORMAT: "DD MMM YYYY"
}

export function getCurrentDate(format) {
  return moment().format(format || DATE_TIME_FORMATS.ISO_FORMAT)
}

// function to seconds into Hours,minutes and seocnds even if seconds are greater than 86,400 seconds (which is equal to one day)
export function convertHMS(seconds) {
  return [3600, 60]
    .reduceRight(
      (pipeline, breakpoint) => remainder =>
        [Math.floor(remainder / breakpoint)].concat(
          pipeline(remainder % breakpoint)
        ),
      r => [r]
    )(seconds)
    .map(amount => amount.toString().padStart(2, "0"))
    .join(":")
}

/**
 * @param {string} date - The format param the date
 * @param {string} toFormat - The toFormat param to convert date into formatted date
 * @param {string} fromFormat - The fromFormat param is is used to give hint to moment (if needed)
 *
 */
export function formatDate(date, toFormat, fromFormat) {
  if (fromFormat) {
    return (
      moment
        //made this .utc change because dates in LAMININ FORMAT were showing 1 day earlier
        .utc(new Date(date))
        .format(toFormat || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN)
    )
  }
  return moment
    .utc(date)
    .local()
    .format(toFormat || DATE_TIME_FORMATS.LAMININ_FORMAT)
}

export function formatDateTime(date, toFormat) {
  return moment(date).format(toFormat || DATE_TIME_FORMATS.FULL_DATE_TIME)
}

// if you want to convert time into UTC ,pass second parameter TRUE
export function TimeToUtcAndLocalAndLocal(time, utc) {
  let dataStartDate
  dataStartDate = formatDate(new Date(), "YYYY-MM-DD")
  dataStartDate = formatDate(
    `${dataStartDate}  ${time}`,
    "YYYY-MM-DD HH:mm",
    utc ? true : false
  )
  dataStartDate = formatDateTime(dataStartDate, "HH:mm")
  return dataStartDate
}

export function StringToTime(time) {
  let dataStartDate
  dataStartDate = formatDateTime(new Date(), "YYYY-MM-DD")
  dataStartDate = formatDateTime(`${dataStartDate} ${time}`, "YYYY-MM-DD HH:mm")
  dataStartDate = formatDateTime(dataStartDate, "HH:mm")
  return dataStartDate
}

export function UTCToLocalDate(UTCDate) {
  return new Date(UTCDate)
}

export function getRelativeDate(date, format) {
  return moment(
    formatDate(date, format),
    format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  ).fromNow()
}

/**
 * @function getAddedDate
 * @param {Date} date - Date
 * @param {string} format - Format of the date provided
 * @param {string} type - type of addition i.e months, days, years
 * @param {string} value - added value
 * @description
 *   This function gets the date , format , type and value and returns the added date
 * @return returns the added date
 * */
export function getAddedDate(date, format, type, value) {
  return moment(
    date,
    format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  )
    .add(value, type)
    .format(format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN)
}

export function getDateSubtracted(difference, value) {
  return moment(new Date()).subtract(difference, value).format()
}

export function getFormatTime(time, format) {
  return moment(time).format(format || DATE_TIME_FORMATS.Time_Format)
}

export function getTimeInMinutes(time, type) {
  return moment.duration(time, type).asMinutes()
}
/**
 * @function getISODate
 * @param {Date} date - Date
 * @param {string} format - Format of the date provided
 * @description
 *   This function gets the date , format and convert the date into ISO String
 * @return returns ISO String
 * */
export function getISODate(date, format) {
  return moment(date, format).toISOString()
}

/**
 * @function getTimeHours
 * @param {Date} date - Date
 * @description
 *   This function gets the date and convert the date into number as hours
 * @return returns the number as hours
 * */
export function getTimeHours(date, type) {
  return moment.duration(date, type).asHours()
}

/**
 * @function getSubtractedDate
 * @param {Date} date - Date
 * @param {string} format - Format of the date provided
 * @param {string} type - type of subtraction i.e months, days, years
 * @param {string} value - subtraction value
 * @description
 *   This function gets the date , format , type and value and returns the subtracted date
 * @return returns the subtracted date
 * */
export function getSubtractedDate(date, format, type, value) {
  return moment(
    date,
    format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  )
    .subtract(value, type)
    .format(format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN)
}

/**
 * @function getMonthFirstDateAfterSubtracting
 * @param {Date} date - Date
 * @param {string} format - Format of the date provided
 * @param {string} type - type of subtraction i.e months, days, years
 * @param {string} value - subtraction value
 * @description
 *   This function gets the date , format , type and value and returns the subtracted date
 * @return returns the subtracted date
 * */
export function getMonthFirstDateAfterSubtracting(date, format, type, value) {
  return moment(
    date,
    format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  )
    .subtract(value, type)
    .startOf("month")
    .format(format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN)
}
/**
 * @function getDiffBetweenDates
 * @param {Date} fromDate - Date
 * @param {Date} toDate - Date
 * @param {string} format - Format of the date provided
 * @param {string} type - type of addition i.e months, days, years
 * @description
 *   This function gets the dates and returns the difference
 * @return returns the difference between fromDate and toDate
 * */
export function getDiffBetweenDates(fromDate, toDate, format, type) {
  return moment(fromDate, format).diff(moment(toDate, format), type)
}

/**
 * @function isBetween
 * @param {Date} date - Date
 * @param {Date} fromDate - Date
 * @param {Date} toDate - Date
 * @param {string} format - Format of the date provided
 * @description
 *   This gets date, fromDate and toDate and return true in current date is between fromDate and toDate
 * @return return true in current date is between fromDate and toDate
 * */
export function isBetween(date, fromDate, toDate, format) {
  if (
    moment(
      date,
      format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
    ).isSameOrAfter(fromDate) &&
    moment(
      date,
      format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
    ).isSameOrBefore(toDate)
  ) {
    return true
  }
}

/**
 * @function getNameOfMonth
 * @param {Date} value - Date
 * @param {string} format - Format of value provided
 * @description
 *   This function gets the date and its format and returns month number
 * @return returns month number
 * */
export function getNameOfMonth(value, format) {
  return moment(value, format).format("MMMM")
}

/**
 * @function isSame
 * @param {Date} firstDate - initial date want to compare
 * @param {Date} secondDate - final date want to compare
 * @param {String} compare - comparison type i.e month, day, week
 * @param {Number} format - format of @param firstDate and secondDate
 * @description
 *   This function gets the two dates firstDate and secondDate, and return true as per comparison
 * @return returns true as per comparison
 * */
export function isSame(firstDate, secondDate, compare, format) {
  return moment(
    firstDate,
    format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  ).isSame(secondDate, compare)
}

/**
 * @function - This function will return true if selected date is belongs to current month.
 * @param {string} date - The date selected by the user
 *
 */
export function isDateBelongsToCurrentMonth(date) {
  const currentMonth = getCurrentDate(DATE_TIME_FORMATS.MONTH)
  const monthOfSelectedDate = formatDate(
    date,
    DATE_TIME_FORMATS.MONTH,
    DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  )

  if (currentMonth === monthOfSelectedDate) {
    return true
  }

  return false
}

/**
 * @function - This function will return true if selected date is belongs to forward to current date.
 * @param {string} date - The date selected by the user
 *
 */
export function isForwardDate(date, format) {
  const currentDate = getCurrentDate(
    format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  )

  return moment(date).isAfter(currentDate, "day")
}

/**
 * @function - This function will return true if selected date is Same Or belongs to forward to current date.
 * @param {string} date - The date selected by the user
 *
 */
export function isSameOrForwardDate(date, format) {
  const currentDate = getCurrentDate(
    format || DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN
  )

  return moment(date).isSameOrAfter(currentDate, "day")
}

/**
 * @function - This function will return the number of days belongs to active month from array of dates
 * @param {array} arrayOfDates - Array of dates
 * @param {string} activeMonthDate - Any date from active month
 *
 */
export function countDatesBelongsToMonthFromArray(
  arrayOfDates,
  activeMonthDate
) {
  let counter = 0
  arrayOfDates.forEach(date => {
    if (moment(date).isSame(activeMonthDate, "month")) {
      counter++
    }
  })

  return counter
}

/**
 * @function - This function will return length of dates belongs to active week
 * @param {array} arrayOfDates - Array of dates
 * @param {string} activeMonthDate - Any date from active month
 *
 */
export function allowDateSelectionAccordingToWeeklyBalance(
  arrayOfDates,
  selectedDate
) {
  const selectedDatesOfSelectedWeek = arrayOfDates.filter(date => {
    return moment(date).isSame(selectedDate, "week")
  })

  return selectedDatesOfSelectedWeek.length
}

/**
 * @function getPreviousArrayOfDates
 * @param {Date} date - Date
 * @param {Number} value - number for previous days
 * @param {Number} type - type
 * @param {Number} format - format of @param date
 * @description
 *   This function gets the date and days as param and returns the array of dates before provides dates of length days
 * @return returns the array of dates
 * */
export const getPreviousArrayOfDates = (date, value, type, format) => {
  const weekDates = []

  for (let index = 0; index < value; index++) {
    weekDates.push(formatDate(getAddedDate(date, format, type, index), format))
  }

  return weekDates
}

/**
 * @function getPreviousArrayOfMonths
 * @param {Date} date - Date
 * @param {Number} value - number for previous months
 * @param {Number} type - type
 * @param {Number} format - format of @param date
 * @description
 *   This function gets the date and days as param and returns the array of months before provides dates of length days
 * @return returns the array of dates
 * */
export const getPreviousArrayOfMonths = (
  date,
  value,
  type,
  format,
  fromFormat
) => {
  const weekDates = []

  for (let index = 0; index < value; index++) {
    weekDates.push(
      formatDate(
        getAddedDate(date, fromFormat, type, index + 1),
        format,
        fromFormat
      )
    )
  }

  return weekDates
}

/**
 * @function getArrayOfDatesBetweenDates
 * @param {Date} fromDate - Date
 * @param {Date} toDate - Date
 * @param {Number} format - format of @param date
 * @description
 *   This function gets the fromDate and toDate and returns array of dates between fromDate and toDate.
 * @return  returns array of dates between fromDate and toDate.
 * */
export const getArrayOfDatesBetweenDates = (fromDate, toDate, format) => {
  const Dates = []

  const diff = getDiffBetweenDates(
    fromDate,
    toDate,
    DATE_TIME_FORMATS.DATE_YEAR_MONTH_DATE_WITH_HYPHEN,
    "day"
  )

  for (let index = 0; index <= Math.abs(diff); index++) {
    const Date = getAddedDate(fromDate, format, "day", index)

    Dates.push(formatDate(Date, format))
  }

  return Dates
}

export const getYearsPassed = fromDate => {
  return moment().diff(fromDate, "years")
}

export const getMinutesPassed = fromDate => {
  return moment().diff(fromDate, "minutes")
}

export const getPreviousDate = date => {
  return new Date(moment(date).subtract(1, "days")).toDateString()
}
export const getPreviousMonth = date => {
  return new Date(moment(date).subtract(1, "month")).toDateString()
}

export const getNextDate = date => {
  return new Date(moment(date).add(1, "days")).toDateString()
}

const pad = num => {
  return `0${num}`.slice(-2)
}
export const secondsToTime = secs => {
  let minutes = Math.floor(secs / 60)
  secs = secs % 60
  const hours = Math.floor(minutes / 60)
  minutes = minutes % 60
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
}

export const getDaysDiff = (
  start_date,
  end_date,
  date_format = "YYYY-MM-DD"
) => {
  const getDateAsArray = date => {
    return moment(date.split(/\D+/), date_format)
  }
  if (
    getDateAsArray(end_date).diff(getDateAsArray(start_date), "days") + 1 >=
    0
  ) {
    return getDateAsArray(end_date).diff(getDateAsArray(start_date), "days") + 1
  } else return 0
}

export const addDurationInDate = (date, plannedDuration = "00:00") => {
  const [hours, minutes] = plannedDuration.split(":")
  return moment(date).add({ hours, minutes })
}
