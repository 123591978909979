import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpService } from "@services/api"

export const KnowledgeBaseData = createSlice({
  name: "knowledgebase",
  initialState: {
    getKnowledgeBaseGetCategoriesData: [],
    getSubCategories: [],
    getArticle: [],
    getArticleData: [],
    ShowCards: true,
    cardsLoading: false
  },
  reducers: {
    getMenuData: (state, action) => {
      state.getKnowledgeBaseGetCategoriesData = action.payload
    },
    setgetSubCategories: (state, action) => {
      state.getSubCategories = action.payload
    },
    setGetArticle: (state, action) => {
      state.getArticle = action.payload
    },
    SetgetArticleData: (state, action) => {
      state.getArticleData = action.payload
    },
    setShowCards: (state, action) => {
      state.ShowCards = action.payload
    },
    setcardsLoading: (state, action) => {
      state.cardsLoading = action.payload
    }
  }
})
export const {
  getMenuData,
  setgetSubCategories,
  setGetArticle,
  SetgetArticleData,
  setShowCards,
  setcardsLoading
} = KnowledgeBaseData.actions

export const getKnowledgeBaseGetCategories = createAsyncThunk(
  "KnowledgeBase/GetCategories",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "KnowledgeBase/GetCategories",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(getMenuData(response?.data?.data))
    } else {
      dispatch(getMenuData([]))
    }
  }
)

export const GetKnowledgeBaseSubCategories = createAsyncThunk(
  "KnowledgeBase/GetSubCategories",
  async (categoryId, { dispatch }) => {
    dispatch(setcardsLoading(true))
    const response = await httpService(
      "GET",
      `KnowledgeBase/GetSubCategories/${categoryId}`,
      {},
      ""
    )
    if (response.status === 200) {
      dispatch(setcardsLoading(false))
      dispatch(setgetSubCategories(response?.data?.data))
    } else {
      dispatch(setcardsLoading(false))
      dispatch(setgetSubCategories([]))
    }
  }
)

export const GetKnowledgeBaseArticle = createAsyncThunk(
  "KnowledgeBase/GetArticles",
  async (SubCategoriesId, { dispatch }) => {
    dispatch(setcardsLoading(true))
    const response = await httpService(
      "GET",
      `KnowledgeBase/GetArticles/${SubCategoriesId}`,
      {},
      ""
    )
    if (response.status === 200) {
      dispatch(setcardsLoading(false))
      dispatch(setGetArticle(response?.data?.data))
    } else {
      dispatch(setGetArticle([]))
    }
  }
)
export const GetKnowledgeBaseArticleData = createAsyncThunk(
  "KnowledgeBase/GetArticalData",
  async (ArticleDataId, { dispatch }) => {
    dispatch(setcardsLoading(true))
    const response = await httpService(
      "GET",
      `KnowledgeBase/GetArticalData/${ArticleDataId}`,
      {},
      ""
    )
    if (response.status === 200) {
      dispatch(setcardsLoading(false))
      dispatch(SetgetArticleData(response?.data?.data))
    } else {
      dispatch(SetgetArticleData([]))
      dispatch(setcardsLoading(false))
    }
  }
)
export default KnowledgeBaseData.reducer
