// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpService } from "@services/api"

export const AnalyticsTableData = createSlice({
  name: "AnalyticsTableData",
  initialState: {
    projectData: [],
    total: 1,
    dataLoading: false,
    teamData: [],
    params: {},
    allData: [],
    employeeData: []
  },
  reducers: {
    analyticsProjectData: (state, action) => {
      state.projectData = action.payload
    },
    analyticsEmployeeData: (state, action) => {
      state.employeeData = action.payload
    },
    analyticsTeamData: (state, action) => {
      state.teamData = action.payload
    },
    loadingStatus: (state, action) => {
      state.dataLoading = action.payload
    }
  }
})
export const {
  analyticsProjectData,
  analyticsTeamData,
  analyticsEmployeeData,
  loadingStatus
} = AnalyticsTableData.actions

export const getAnalyticsProjectData = createAsyncThunk(
  "AnalyticsTableData/getData",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "GET",
      "/Analytics/projectslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(analyticsProjectData(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(analyticsProjectData([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getAnalyticsEmployeeData = createAsyncThunk(
  "AnalyticsTableData/getData",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "GET",
      "/Analytics/employeeslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(analyticsEmployeeData(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(analyticsEmployeeData([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getAnalyticsTeamData = createAsyncThunk(
  "AnalyticsTableData/getData",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))

    const response = await httpService(
      "GET",
      "/Analytics/teamslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(analyticsTeamData(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(analyticsTeamData([]))
      dispatch(loadingStatus(false))
    }
  }
)
export default AnalyticsTableData.reducer
