/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
// ** dependencies import
import { useEffect } from "react"
import moment from "moment"
import { Badge, UncontrolledTooltip } from "reactstrap"
import * as yup from "yup"
import _ from "lodash"
import { GitMerge, X } from "react-feather"
import Avatar from "@components/avatar"
import { store } from "../redux/store"
import Toaster from "@modules/Toaster"
const CryptoJS = require("crypto-js")
import { renderUserSelfCreated } from "../views/pages/MyTasks/Utils"

export const encrypData = data => {
  const encryptedMessage = {}

  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SOCIAL_SECRET_KEY)
  const iv = CryptoJS.enc.Utf8.parse("00000000000000000000000000000000")

  const encrypt = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  encryptedMessage.iv = iv
  encryptedMessage.token = encrypt.ciphertext.toString(CryptoJS.enc.Base64)
  return encryptedMessage
}

export const decryptData = data => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SOCIAL_SECRET_KEY)
  const iv = CryptoJS.enc.Utf8.parse("00000000000000000000000000000000")
  const encrypt = CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return JSON.parse(encrypt.toString(CryptoJS.enc.Utf8)) || {}
}

export const getDataFromLocalStorage = data => {
  const userData = localStorage.getItem(data)
  let decrypted
  if (userData) {
    decrypted = decryptData(userData)
  }
  return decrypted
}
export const setDataInLocalStorage = (data, name) => {
  const encrypted = encrypData(data)
  localStorage.setItem(name, encrypted.token)
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => obj && Object.keys(obj)?.length === 0

// ** Returns K format from a number
export const kFormatter = num =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num
// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, "")
// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}
/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  const formatting = { month: "short", day: "numeric" }
  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => getDataFromLocalStorage("userDataAuth")
export const getUserData = () => getDataFromLocalStorage("userDataAuth")

export const getBearerToken = async () => {
  const accessToken = JSON.parse(localStorage.getItem("accessToken"))
  if (accessToken) {
    return `Bearer ${accessToken}`
  }

  return "no-token"
}
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole) {
    const userData = getDataFromLocalStorage("userDataAuth")
    const { userOrgs } = userData
    return userOrgs?.length > 1 ? "/choose-organization" : "/dashboard"
  }
  return "/login"
}
// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})
// ** Function for Collaborator-Observer
export const permissionActions = (viewScreen, crud) => {
  const userRights = getDataFromLocalStorage("userDataAuth")?.userRights
  const screen = userRights?.find(rights => rights?.screenName === viewScreen)

  if (screen?.[crud]) {
    return screen?.[crud]
  }
}
// ** Function to return a user type
export const UserType = () => getDataFromLocalStorage("userDataAuth")?.userType

// ** Function to render a status base bullet
export const bulletColor = status => {
  if (status === 4 || status === "4") return "success"
  if (status === 3 || status === "3" || status === "Low") return "primary"
  if (status === 2 || status === "2" || status === "Medium") return "warning"
  if (status === 1 || status === "1" || status === "High") return "danger"
  if (status === 10 || status === "10") return "secondary"
  else return "secondary"
}
export const returnPriority = status => {
  if (status === 1 || status === "1") {
    return (
      <Badge className="text-capitalize" color="light-primary" pill>
        Low
      </Badge>
    )
  }
  if (status === 2 || status === "2") {
    return (
      <Badge className="text-capitalize" color="light-warning" pill>
        Medium
      </Badge>
    )
  }
  if (status === 3 || status === "3") {
    return (
      <Badge className="text-capitalize" color="light-danger" pill>
        High
      </Badge>
    )
  }
  if (status === null || status === "") {
    return ""
  } else return ""
}
// ** Function to render a badge based on priority
export const renderTags = type => {
  const badgeColor = {
    LOW: "light-primary",
    MEDIUM: "light-warning",
    HIGH: "light-danger"
  }
  return (
    <Badge
      className="text-capitalize"
      color={badgeColor[type.toUpperCase()]}
      pill
    >
      {type}
    </Badge>
  )
}
export const checkPasswordValidation = () => {
  return yup
    .string()
    .matches(/^\S+$/, "Password must not contain spaces")
    .matches(
      /^(?=.*[A-Z])/,
      "Password must have at least one Uppercase Character."
    )
    .matches(
      /^(?=.*[a-z])/,
      "Password must have at least one Lowercase Character."
    )
    .matches(/^(?=.*[0-9])/, "Password must contain at least one digit.")
    .matches(
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/,
      "Password must contain at least one Special Symbol"
    )
    .matches(/^.{8,24}$/, "Password must be 8-24 Characters Long.")

    .required()
}
// ** Function to return status type
export const returnStatus = status => {
  if (status === 1 || status === "1") return "To do"
  if (status === 2 || status === "2") return "On hold"
  if (status === 3 || status === "3") return "in progress"
  if (status === 4 || status === "4") return "completed"
  if (status === 10 || status === "10") return "Backlog"
  else return "Others"
}
// ** Function to return status type
export const returnStrPriority = priority => {
  switch (priority) {
    case "Medium":
      return "Medium"
    case "High":
      return "High"
    case "Low":
      return "Low"
    default:
      return "No Group"
  }
}
// ** Function to format data
export const formatDate = date => {
  return date ? moment(date).format("DD-MMM-YY") : ""
}
export const formatDate1 = date => {
  return moment(date).format("DD-MMM-YY hh:mm")
}
export const formatDateIntoTime = date => {
  return moment(date).format("hh:mm:ss")
}
// ** Function to return time Duration in Hours and Minutes as per standard format 00:00
export const formatDuration = time => {
  const [hours, minutes] = time?.split(":")
  if (hours < 10) {
    return `0${hours}:${minutes}`
  } else if (hours > 9) {
    return `${hours}:${minutes}`
  }
}
// ** Function to disable body scroll upon Modal or Drawer Open
export const useDisableBodyScroll = open => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [open])
}
// ** Function to Calculate Percentage
export const calculatePercentage = (done, total) => {
  const percentage = (done / total) * 100
  return percentage.toFixed(0)
}

// ** Function Reverse Array by date
export const sortArrByDate = (arr, date) => {
  const arrCopy = [...arr]
  arrCopy.reverse((a, b) => a[date] - b[date])
  return arrCopy
}
// ** Function Sort Array by date
export const sortArrByDate2 = (arr, date) => {
  const arrCopy = [...arr]
  arrCopy.sort((a, b) => moment(a[date]) - moment(b[date]))
  return arrCopy
}

// ** Function Group by key
export const groupByKey = (arr, key) => {
  const userData = getDataFromLocalStorage("userDataAuth")
  let newObj = {}
  const dateFormatter = date => {
    return moment.utc(date).local().format("DD-MMM-YY")
  }

  const renderFormattedDate = x => {
    switch (key) {
      case "plannedEndDate":
        return x[key] ? dateFormatter(x[key]) : "Others"
      case "clockOut":
        return x[key] ? dateFormatter(x[key]) : "Others"
      case "lastWorkDate":
        return x[key] ? dateFormatter(x[key]) : "Others"
      default:
        return x[key] ? x[key] : 0
    }
  }
  const startedTaskIndex = arr.findIndex(
    item =>
      item.clockIn !== null &&
      item.clockOut === null &&
      item.userId === userData?.id
  )
  const tasksArr = _(arr)
    .groupBy(x => {
      return renderFormattedDate(x)
    })

    .map((value, newKey) => ({ [key]: newKey, data: value, id: newKey }))
    .value()
  const index = tasksArr.findIndex(({ id }) => id === "Others")
  const object = tasksArr[index]

  if (index > -1 && tasksArr.length > 1) {
    tasksArr.splice(index, 1)
    tasksArr.push(object)
  }
  if (startedTaskIndex >= 0) {
    const startedObject = arr[startedTaskIndex]
    tasksArr.forEach(function (arr) {
      arr.data = arr.data.filter(s => s.taskId !== startedObject.taskId)
    })

    newObj = { [key]: "in Progress", data: [startedObject], id: "in Progress" }
    tasksArr.unshift(newObj)
  }

  return tasksArr
}
// funtion used in Filter for DateTimePicker to scroll down in it
export function scrollToBottomOnCalanderOpen(label, containerRef) {
  useEffect(() => {
    if (label === "Custom Range" && containerRef.current) {
      const containerElement = containerRef.current
      const { scrollHeight, clientHeight } = containerElement
      const maxScrollTop = scrollHeight - clientHeight

      if (maxScrollTop > 0) {
        containerElement.scrollTo({
          top: maxScrollTop,
          behavior: "smooth"
        })
      }
    }
  }, [label])
}

export const checkTimeDifference = (now, then) => {
  const newDate = moment
    .utc(
      moment(now, "DD/MM/YYYY HH:mm:ss").diff(
        moment(then, "DD/MM/YYYY HH:mm:ss")
      )
    )
    .format("YYYY-MM-DDTHH:mm:ss.SSSZ")

  return new Date(newDate).getTime()
}

export const ModalHeader = props => {
  // ** Props
  const {
    title,
    handleTaskSidebar,
    buttonsbar,
    disabledCancelBtn = false
  } = props
  return (
    <div className="sticky-div">
      <div className="modal-header  d-flex align-items-center justify-content-between">
        <h5 className="modal-title">{title}</h5>
        <div className="todo-item-action d-flex align-items-center">
          {disabledCancelBtn === false ? (
            <X
              className="fw-normal mt-25 cursor-pointer"
              size={16}
              onClick={handleTaskSidebar}
            />
          ) : null}
        </div>
      </div>
      {buttonsbar ? buttonsbar() : ""}
    </div>
  )
}
export const AvatarAndNameDisplay = props => {
  const {
    id,
    avatarID,
    title,
    imagePath,
    avatarColour,
    avatarName,
    stylesfortext,
    parentTaskId,
    taskId,
    isSelfCreated,
    status,
    paddingLeft,
    noNeedIsSelfCreated,
    customTooltip = ""
  } = props
  return (
    <>
      {isSelfCreated ? (
        <div className="text-center me-50">
          {renderUserSelfCreated(isSelfCreated, taskId)}
        </div>
      ) : noNeedIsSelfCreated ? (
        ""
      ) : (
        <div
          className="me-50"
          style={{
            width: 14
          }}
        ></div>
      )}
      {imagePath !== undefined ? (
        imagePath !== "" ? (
          <>
            <Avatar
              id={avatarID}
              content={avatarName
                ?.split(" ")
                ?.slice(0, 2)
                ?.join(" ")
                ?.toUpperCase()}
              img={imagePath}
              initials
            />
            <UncontrolledTooltip placement="top" target={avatarID}>
              {avatarName}
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <Avatar
              id={avatarID}
              color={avatarColour ? avatarColour : "secondary"}
              content={avatarName
                ?.split(" ")
                ?.slice(0, 2)
                ?.join(" ")
                ?.toUpperCase()}
              initials
            />
            <UncontrolledTooltip placement="top" target={avatarID}>
              {avatarName}
            </UncontrolledTooltip>
          </>
        )
      ) : null}

      {status ? (
        <div className="d-flex ps-50 flex-column">
          <div className="badge-wrapper  text-center mx-auto">
            <span
              className={` bullet bullet-${bulletColor(status)} bullet-sm`}
              id={`status${taskId}`}
            >
              <UncontrolledTooltip placement="top" target={`status${taskId}`}>
                <span className="text-capitalize">{returnStatus(status)}</span>
              </UncontrolledTooltip>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {parentTaskId && (
        <span className="ms-75">
          <>
            {" "}
            <GitMerge id={`subtask${avatarID}`} size={14} />
            <UncontrolledTooltip
              placement="right"
              target={`subtask${avatarID}`}
            >
              Subtask
            </UncontrolledTooltip>
          </>
        </span>
      )}
      <span
        className={`d-inline-block text-truncate ${
          paddingLeft === false ? "" : "ps-50"
        }`}
        style={{ maxWidth: stylesfortext ? stylesfortext : "260px" }}
        id={id}
      >
        {title}
        <UncontrolledTooltip placement="right" target={id}>
          {customTooltip === "" ? title : customTooltip}
        </UncontrolledTooltip>
      </span>
    </>
  )
}

export const checkRights = right => {
  const userData = getDataFromLocalStorage("userDataAuth")

  const index = userData?.userRights?.findIndex(
    item => item?.screenDescription === right && item?.viewScreen === true
  )
  if (index > -1) {
    return true
  } else {
    return false
  }
}

export const empProjLimit = () => {
  const { userData } = store.getState()?.auth
  const { userOrgs } = userData
  const currentOrg = userOrgs?.find(org => org?.tenantId === userData?.tenantId)
  const isProjectLimit =
    currentOrg?.totalAllowProjects > currentOrg?.currentProjects
  const isEmployeeLimit = currentOrg?.totalAllowEmps > currentOrg?.currentEmps
  return {
    isProjectLimit,
    isEmployeeLimit
  }
}
export const copyToClipboard = taskId => {
  navigator.clipboard
    .writeText(`${window.location.origin}/tasks?id=${taskId}`)
    .then(() => {
      Toaster("success", "Wohoo! You have copied a task", "Success")
    })
    .catch(err => Toaster("error", err.message, "failed"))
}
export const MAXIMUM_LIMIT_REACH_MESSAGE = "You Have Reached The Maximum Limit"

export const renderUsersDDL = (usersDDL, usersByProject, project) => {
  let employeeOptions
  if (project) {
    employeeOptions =
      usersByProject &&
      usersByProject.map(user => ({
        label: `${user.empName} - ${user.teamName}`,
        value: user.userId,
        imagePath: user.imagePath
      }))
    return employeeOptions
  } else {
    employeeOptions =
      usersDDL &&
      usersDDL?.map(team => ({
        value: team.value,
        label: team.text,
        imagePath: team.imagePath
      }))
    return employeeOptions
  }
}
export const amountFormat = (
  amount,
  decimal = 0,
  currency = "$",
  format = true
) => {
  const formattedAmount = amount
    ? Number(amount)
        .toFixed(decimal)
        .replace(/\B(?=(\d{3})+(?!\d))/g, `${format ? "," : ""}`)
    : 0
  return currency + formattedAmount
}
