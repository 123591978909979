// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// custom Toaster
import Toaster from "@modules/Toaster"
import { handleProjectSingleDDL } from "@views/pages/MyTasks/store"
import { employeesData } from "../../Employees/Store"
import { allProjects } from "@views/admin/MyProjects/store"

export const projectTablesSlice = createSlice({
  name: "projecttables",
  initialState: {
    getProjectDDL: [],
    getProjectData: [],
    getEditProject: [],
    getExistingEmployee: [],
    getEmployeesDDL: [],
    getExistingManagers: [],
    getManagersDDL: [],
    getObserverList: [],
    isLoading: false,
    loader: false,
    observerLoading: false,
    existingManagerListLoader: false,
    existingEmployeeListLoader: false,
    managersDDLLoader: false,
    employeesDDLLoader: false,
    observerListLoading: false,
    deleteObserverLoader: false
  },
  reducers: {
    observerListLoading: (state, action) => {
      state.observerListLoading = action.payload
    },
    getObserverList: (state, action) => {
      state.getObserverList = action.payload
    },
    employeesDDLLoader: (state, action) => {
      state.employeesDDLLoader = action.payload
    },
    managersDDLLoader: (state, action) => {
      state.managersDDLLoader = action.payload
    },
    existingEmployeeListLoader: (state, action) => {
      state.existingEmployeeListLoader = action.payload
    },
    existingManagerListLoader: (state, action) => {
      state.existingManagerListLoader = action.payload
    },
    observerLoading: (state, action) => {
      state.observerLoading = action.payload
    },
    getProjectDDL: (state, action) => {
      state.getProjectDDL = action.payload
    },
    projectsData: (state, action) => {
      state.getProjectData = action.payload
    },
    editProjectsData: (state, action) => {
      state.getEditProject = action.payload
    },
    getExistingEmployee: (state, action) => {
      state.getExistingEmployee = action.payload
    },
    getEmployeesDDL: (state, action) => {
      state.getEmployeesDDL = action.payload
    },
    getExistingManager: (state, action) => {
      state.getExistingManagers = action.payload
    },
    getManagerDDL: (state, action) => {
      state.getManagersDDL = action.payload
    },
    loadingProjects: (state, action) => {
      state.isLoading = action.payload
    },
    loadingStatus: (state, action) => {
      state.loader = action.payload
    },
    deleteObserverLoader: (state, action) => {
      state.deleteObserverLoader = action.payload
    }
  }
})

export const {
  getProjectDDL,
  deleteObserverLoader,
  getObserverList,
  observerListLoading,
  observerLoading,
  projectsData,
  editProjectsData,
  getExistingEmployee,
  getEmployeesDDL,
  getExistingManager,
  getManagerDDL,
  loadingProjects,
  loadingStatus,
  existingManagerListLoader,
  existingEmployeeListLoader,
  managersDDLLoader,
  employeesDDLLoader
} = projectTablesSlice.actions
export default projectTablesSlice.reducer

export const getProjectData = createAsyncThunk(
  "projecttable/getData",
  async (params, { dispatch }) => {
    dispatch(loadingProjects(true))
    params = { PageSize: 1000, page: 1 }
    const response = await httpService(
      "GET",
      "Projects/allprojectslist",
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(projectsData(response?.data?.data))
      dispatch(loadingProjects(false))
    } else {
      dispatch(projectsData([]))
      dispatch(loadingProjects(false))
    }
  }
)
export const getProjectsDDL = createAsyncThunk(
  "projecttable/getProjectsDDL",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/Projects/addproject",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getProjectDDL(response?.data))
    }
  }
)
export const postProject = createAsyncThunk(
  "projecttable/postProject",
  async (params, { dispatch, getState }) => {
    dispatch(loadingStatus(true))
    const { isActive } = params
    const response = await httpService(
      "POST",
      "/Projects/addproject",
      params,
      ""
    )
    if (response.status === 200) {
      const screen = window?.location?.pathname

      const previousState =
        screen === "/define/projects"
          ? [...getState()?.ProjectData?.getProjectData]
          : [...getState()?.myProjects?.projects]

      const addObj = {
        projectsVM: response.data,
        vProjectwiseSummary: response.data
      }

      screen === "/define/projects"
        ? dispatch(projectsData([addObj, ...previousState]))
        : getState()?.myProjects?.activeStatus === isActive
        ? dispatch(allProjects([addObj, ...previousState]))
        : ""

      dispatch(loadingStatus(false))
      dispatch(
        handleProjectSingleDDL({
          label: response?.data?.projectName,
          value: response?.data?.projectId
        })
      )
      Toaster("success", "Project Added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      if (response?.data?.response?.data?.errors[0]) {
        Toaster("error", response?.data?.response?.data?.errors[0], "Failed")
      } else {
        Toaster("error", "Something went wrong please try again.", "Failed")
      }
    }
    return response
  }
)

export const editProject = createAsyncThunk(
  "projecttable/editProject",
  async (params, { dispatch, getState }) => {
    dispatch(loadingStatus(true))
    const { isActive } = params
    const response = await httpService(
      "POST",
      "/Projects/editproject",
      params,
      ""
    )
    if (response.status === 200) {
      const previousState = [...getState().myProjects.projects]

      const index = previousState?.findIndex(
        item => item?.projectsVM?.projectId === params?.projectId
      )
      const updatedObj = {
        projectsVM: response.data,
        vProjectwiseSummary: previousState[index].vProjectwiseSummary
      }
      getState().myProjects.activeStatus === isActive
        ? (previousState[index] = updatedObj)
        : previousState.splice(index, 1)

      dispatch(loadingStatus(false))
      dispatch(allProjects(previousState))
      Toaster("success", "Project Edit Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      if (response?.data?.response?.data?.errors[0]) {
        Toaster("error", response?.data?.response?.data?.errors[0], "Failed")
      } else {
        Toaster("error", "Something went wrong please try again.", "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)

export const removeExistingEmployee = createAsyncThunk(
  "projecttable/removeExistingEmployee",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "POST",
      "/Projects/RemoveProjectEmployee",
      "",
      params
    )
    if (response?.status === 200) {
      const data = getState()?.ProjectData?.getExistingEmployee?.filter(
        item => item?.userId !== params?.UserId
      )
      dispatch(getExistingEmployee(data))
      Toaster("success", response?.data?.message, "Success")
    } else {
      if (response?.data?.response?.data?.message) {
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      } else {
        Toaster("error", response?.data?.message, "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)

export const removeExistingEmployeeAndResignTasks = createAsyncThunk(
  "projecttable/removeExistingEmployeeAndResignTasks",
  async (params, { dispatch, getState }) => {
    const { ProjectId, ...resignTasksParams } = params
    const resignTasksResponse = await httpService(
      "POST",
      `/Employees/reassignusertasks`,
      resignTasksParams,
      ""
    )
    // eslint-disable-next-line
    const { projectTasks, ...removeUserParams } = params
    if (resignTasksResponse.status === 200) {
      const RemoveUserResponse = await httpService(
        "POST",
        "/Projects/RemoveProjectEmployee",
        "",
        removeUserParams
      )
      if (RemoveUserResponse?.status === 200) {
        const { employeeData } = getState()?.EmployeeData
        const updatedData = employeeData?.filter(
          item => item?.userId !== params?.userId
        )
        dispatch(employeesData(updatedData))
        const data = getState()?.ProjectData?.getExistingEmployee?.filter(
          item => item?.userId !== params?.userId
        )
        dispatch(getExistingEmployee(data))
        Toaster("success", RemoveUserResponse?.data?.message, "Success")
      } else {
        if (RemoveUserResponse?.data?.response?.data?.message) {
          Toaster(
            "error",
            RemoveUserResponse?.data?.response?.data?.message,
            "Failed"
          )
        } else {
          Toaster("error", RemoveUserResponse?.data?.message, "Failed")
        }
      }
    } else {
      Toaster("error", resignTasksResponse?.data?.message, "Failed")
    }
  }
)
export const removeObserver = createAsyncThunk(
  "projecttable/removeObserver",
  async (params, { dispatch, getState }) => {
    dispatch(deleteObserverLoader(params?.ObserverId))
    const response = await httpService(
      "POST",
      "/Projects/RemoveProjectObserver",
      "",
      params
    )
    if (response?.status === 200) {
      const data = getState()?.ProjectData?.getObserverList?.filter(
        item => item?.userId !== params?.ObserverId
      )
      dispatch(getObserverList(data))
      dispatch(deleteObserverLoader(false))
      Toaster("success", response?.data?.message, "Success")
    } else {
      dispatch(deleteObserverLoader(false))
      if (response?.data?.response?.data?.message) {
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      } else {
        Toaster("error", response?.data?.message, "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)
export const addProjectObserver = createAsyncThunk(
  "projecttable/addProjectObserver",
  async (params, { dispatch }) => {
    dispatch(observerLoading(true))
    const response = await httpService(
      "POST",
      "/Projects/AddProjectObservers",
      params,
      ""
    )
    if (response?.status === 200) {
      dispatch(observerLoading(false))
      Toaster("success", response?.data?.message, "Success")
    } else {
      dispatch(observerLoading(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const getObserverLists = createAsyncThunk(
  "projecttable/getObserverLists",
  async (params, { dispatch }) => {
    dispatch(observerListLoading(true))
    const response = await httpService(
      "GET",
      "/Projects/GetProjectObservers",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getObserverList(response?.data?.data))
      dispatch(observerListLoading(false))
    } else {
      dispatch(observerListLoading(false))
    }
  }
)
export const removeExistingManager = createAsyncThunk(
  "projecttable/RemoveProjectManager",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "POST",
      "/Projects/RemoveProjectManager",
      "",
      params
    )
    if (response?.status === 200) {
      const data = getState()?.ProjectData?.getExistingManagers?.filter(
        item => item.userId !== params.UserId
      )
      dispatch(getExistingManager(data))
      Toaster("success", response?.data?.message, "Success")
    } else {
      if (response?.data?.response?.data?.message) {
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      } else {
        Toaster("error", response?.data?.message, "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)

export const getEditProject = createAsyncThunk(
  "projecttable/getEditProject",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/Projects/addproject",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(editProjectsData(response?.data))
    }
  }
)
export const getEmployeeDDL = createAsyncThunk(
  "projecttable/getEmployeeDDL",
  async (params, { dispatch }) => {
    dispatch(employeesDDLLoader(true))
    const response = await httpService("GET", "TeamTasks/addprojectemps", "", {
      projectId: params
    })
    if (response.status === 200) {
      dispatch(getEmployeesDDL(response?.data?.employeesDDL))
      dispatch(employeesDDLLoader(false))
    }
  }
)
export const getExistingEmployees = createAsyncThunk(
  "projecttable/getExistingEmployees",
  async (params, { dispatch }) => {
    dispatch(existingEmployeeListLoader(true))
    const response = await httpService(
      "GET",
      `/TeamTasks/getprojectemps?projectId=${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(existingEmployeeListLoader(false))
      dispatch(getExistingEmployee(response?.data?.data))
    }
  }
)
export const addEmployees = createAsyncThunk(
  "projecttable/addEmployees",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const { employeeList, projectId } = params
    const response = await httpService(
      "POST",
      `/TeamTasks/addprojectemps?projectId=${projectId}`,
      employeeList
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      Toaster("success", "Project Employees Added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
    return response.status === 200 ? true : false
  }
)

export const getManagersDDL = createAsyncThunk(
  "projecttable/getManagersDDL",
  async (params, { dispatch }) => {
    dispatch(managersDDLLoader(true))
    const response = await httpService(
      "GET",
      "/TeamTasks/addprojectmanagers",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(managersDDLLoader(false))
      dispatch(getManagerDDL(response?.data?.employeesDDL))
    }
  }
)
export const getExistingManagers = createAsyncThunk(
  "projecttable/getExistingManagers",
  async (params, { dispatch }) => {
    dispatch(existingManagerListLoader(true))
    const response = await httpService(
      "GET",
      `/TeamTasks/getprojectmanagers?projectId=${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(existingManagerListLoader(false))
      dispatch(getExistingManager(response?.data?.data))
    }
  }
)
export const addProjectManager = createAsyncThunk(
  "projecttable/addProjectManager",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const { managersList, projectId } = params
    const response = await httpService(
      "POST",
      `TeamTasks/addprojectmanagers?projectId=${projectId}`,
      managersList,
      ""
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      Toaster("success", "Project Mangers Added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
    return response.status === 200 ? true : false
  }
)
