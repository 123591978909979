// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"
import {
  getTaskGroupDDLByProjectId,
  handleTaskGroupSingleDDL
} from "@views/pages/MyTasks/store"
import { addTaskGroup } from "@src/views/pages/MyTasks/store"

export const taskGroupTablesSlice = createSlice({
  name: "taskGroupTable",
  initialState: {
    taskGroupData: [],
    tasksGroupDDL: [],
    loader: false,
    isLoading: false
  },
  reducers: {
    taskGroupData: (state, action) => {
      state.taskGroupData = action.payload
    },
    taskGroupDDL: (state, action) => {
      state.tasksGroupDDL = action.payload
    },
    loadingTaskGroup: (state, action) => {
      state.isLoading = action.payload
    },
    loadingStatus: (state, action) => {
      state.loader = action.payload
    }
  }
})

export const { taskGroupData, taskGroupDDL, loadingTaskGroup, loadingStatus } =
  taskGroupTablesSlice.actions
export default taskGroupTablesSlice.reducer

export const getTaskGroupData = createAsyncThunk(
  "taskGroupTable/getTaskGroupData",
  async (params, { dispatch }) => {
    dispatch(loadingTaskGroup(true))
    const response = await httpService(
      "GET",
      "/TaskGroups/tasktypeslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(loadingTaskGroup(false))
      dispatch(taskGroupData(response?.data?.data))
    } else {
      dispatch(loadingTaskGroup(false))
      dispatch(taskGroupData([]))
    }
  }
)
export const getTaskGroupDDL = createAsyncThunk(
  "taskGroupTable/getTaskGroupDDL",
  async (params, { dispatch }) => {
    const response = await httpService("GET", "/TaskGroups/add", "", params)
    if (response.status === 200) {
      dispatch(taskGroupDDL(response?.data))
    }
  }
)
export const postTaskGroup = createAsyncThunk(
  "taskGroupTable/postTaskGroup",
  async (param, { dispatch, getState }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("POST", "/TaskGroups/add", param, "")
    if (response?.status === 200) {
      const updatedData = [
        ...getState().TaskGroup.taskGroupData,
        ...response?.data
      ]
      dispatch(taskGroupData(updatedData))
      dispatch(loadingStatus(false))
      dispatch(getTaskGroupDDLByProjectId({}))
      if (
        param.taskGroupIsOpen !== "globle" &&
        param.taskGroupIsOpen !== "define"
      ) {
        dispatch(
          handleTaskGroupSingleDDL({
            label: response.data[0].taskType.taskType1,
            value: response.data[0].taskType.taskTypeId
          })
        )
      }
      dispatch(addTaskGroup(response.data[0].taskType))
      Toaster("success", "TaskGroup Added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const editTaskGroup = createAsyncThunk(
  "taskGroupTable/editTaskGroup",
  async (param, { dispatch, getState }) => {
    dispatch(loadingStatus(true))
    const prevData = [...getState().TaskGroup.taskGroupData]
    const response = await httpService("POST", "/TaskGroups/edit", param, "")
    if (response?.status === 200) {
      dispatch(loadingStatus(false))
      const index = prevData?.findIndex(
        taskGroup => taskGroup?.taskType?.taskTypeId === param?.taskTypeId
      )
      index > -1 ? prevData.splice(index, 1, response?.data) : ""
      dispatch(taskGroupData(prevData))

      Toaster("success", "TaskGroup Edit Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
