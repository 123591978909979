// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"

export const LoginPage = createSlice({
  name: "LoginPage",
  initialState: {
    Loading: false,
    organizationData: {},
    orgDDL: {},
    menuArr: []
  },
  reducers: {
    organizationData: (state, action) => {
      state.organizationData = action.payload
    },
    handleMenuArr: (state, action) => {
      state.menuArr = action.payload
    },
    loadingStatus: (state, action) => {
      state.Loading = action.payload
    },
    handleOrgDDL: (state, action) => {
      state.orgDDL = action.payload
    }
  }
})

export const { loadingStatus, organizationData, handleOrgDDL, handleMenuArr } =
  LoginPage.actions
export default LoginPage.reducer

export const postResetPassword = createAsyncThunk(
  "loginpage/postResetPassword",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      "/Users/forgotpassword",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      return {
        success: true,
        message: response?.data?.message
      }
    } else {
      dispatch(loadingStatus(false))
      return {
        success: false,
        message: response?.data?.response?.data?.message
      }
    }
  }
)

export const registerUser = createAsyncThunk(
  "register/registerUser",
  async (body, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("POST", "/Users/registerasync", body, "")
    if (response.status === 200) {
      localStorage.setItem("accessToken", response?.data?.token)
      dispatch(loadingStatus(false))
      return { success: true, response: response?.data }
    } else {
      dispatch(loadingStatus(false))
      !body?.isSocialSignup &&
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      return { success: false, response: response?.data }
    }
  }
)

export const createOrganization = createAsyncThunk(
  "register/registerUser",
  async (body, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("POST", "/Organization/addorg", body, "")
    if (response.status === 200) {
      dispatch(loadingStatus(false))
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return { success: response?.status === 200 ? true : false, response }
  }
)

export const postforgotPasswordReset = createAsyncThunk(
  "loginpage/forgotPasswordReset",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      "/Users/forgotpasswordreset",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      return {
        success: true,
        message: "Password Successfully Changed"
      }
    } else {
      dispatch(loadingStatus(false))
      return {
        success: false,
        message: "Something went wrong"
      }
    }
  }
)

export const addOrgDDL = createAsyncThunk(
  "organization/addOrgDDL",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("GET", "Organization/addorg", "", params)
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      dispatch(handleOrgDDL(response?.data))
    } else {
      dispatch(loadingStatus(false))
      dispatch(handleOrgDDL({}))
    }
  }
)

export const getToken = createAsyncThunk(
  "LoginPage/forgotPassword",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "GET",
      "Users/forgotpasswordreset",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
    } else {
      dispatch(loadingStatus(false))
      return {
        page: true,
        message: response?.data?.response?.data?.message
      }
    }
  }
)
export const getOrganizationData = createAsyncThunk(
  "LoginPage/getOrganizationData",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("GET", "Users/switchorg", "", params)
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      return { success: true, response: response?.data }
    } else {
      dispatch(loadingStatus(false))
      return { success: false, response: response?.data }
    }
  }
)
export const updateSignUpSeqLvl = createAsyncThunk(
  "Users/updatesignupseqlvl",
  async (params, {}) => {
    // dispatch(loadingStatus(true))
    const response = await httpService(
      "PUT",
      "Users/updatesignupseqlvl",
      "",
      params
    )
    if (response.status === 200) {
      // dispatch(loadingStatus(false))
      return { success: true, response: response?.data }
    } else {
      // dispatch(loadingStatus(false))
      return { success: false, response: response?.data }
    }
  }
)
