// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"

export const myProjectSlice = createSlice({
  name: "myProject",
  initialState: {
    projects: [],
    tasksStatus: [],
    projectLoadingStatus: false,
    overDueTasks: [],
    UpComingDeadlines: [],
    workload: [],
    projectLoadMoreStatus: false,
    counts: {},
    activeStatus: true,
    projectFiltersDDLsLoader: false,
    projectFiltersDDL: [],
    filterEmployeeDDLs: [],
    alreadyRequestSent: false
  },
  reducers: {
    filterEmployeeDDL: (state, action) => {
      state.filterEmployeeDDLs = action.payload
    },
    projectFiltersDDL: (state, action) => {
      state.projectFiltersDDL = action.payload
    },
    projectFiltersDDLsLoader: (state, action) => {
      state.projectFiltersDDLsLoader = action.payload
    },
    allProjects: (state, action) => {
      state.projects = action.payload
    },
    dataCounts: (state, action) => {
      state.counts = action.payload
    },
    workload: (state, action) => {
      state.workload = action.payload
    },
    UpComingDeadlines: (state, action) => {
      state.UpComingDeadlines = action.payload
    },
    tasksStatus: (state, action) => {
      state.tasksStatus = action.payload
    },
    loadingStatus: (state, action) => {
      state.projectLoadingStatus = action.payload
    },
    loadingStatusLoadmore: (state, action) => {
      state.projectLoadMoreStatus = action.payload
    },
    overDueTasks: (state, action) => {
      state.overDueTasks = action.payload
    },
    handleActiveStatus: (state, action) => {
      state.activeStatus = action.payload
    },
    alreadyRequestSent: (state, action) => {
      state.alreadyRequestSent = action.payload
    }
  }
})
export const {
  allProjects,
  alreadyRequestSent,
  projectFiltersDDLsLoader,
  projectFiltersDDL,
  loadingStatus,
  tasksStatus,
  workload,
  overDueTasks,
  UpComingDeadlines,
  handleActiveStatus,
  dataCounts,
  loadingStatusLoadmore,
  filterEmployeeDDL
} = myProjectSlice.actions

export const deleteProject = createAsyncThunk(
  "myProject/deletePorject",
  async (params, { dispatch, getState }) => {
    const previousData = getState().myProjects.projects
    const previousDataCopy = [...previousData]
    const fileIndex = previousData.findIndex(
      file => file.projectsVM?.projectId === params.ProjectId
    )
    previousDataCopy.splice(fileIndex, 1)
    const response = await httpService(
      "POST",
      "/Projects/RemoveProject",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(allProjects(previousDataCopy))
      Toaster("success", response?.data?.message, "Success")
    } else {
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
  }
)

export const getWorkLoad = createAsyncThunk(
  "myProject/getWorkLoad",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projecttasksworkload",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(workload(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(workload([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const projectFilterDDLs = createAsyncThunk(
  "myProject/projectFilterDDLs",
  async (params, { dispatch }) => {
    dispatch(projectFiltersDDLsLoader(true))
    const response = await httpService(
      "GET",
      "/Projects/getprojectfilters",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(projectFiltersDDL(response?.data))
      dispatch(projectFiltersDDLsLoader(false))
    } else {
      dispatch(projectFiltersDDL([]))
      dispatch(projectFiltersDDLsLoader(false))
    }
  }
)
export const filterEmployeeDDLs = createAsyncThunk(
  "myProject/projectFilterEmployeeDDLs",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/getempslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(filterEmployeeDDL(response?.data?.data))
    } else {
      dispatch(projectFiltersDDL([]))
    }
  }
)
export const getTasksStatus = createAsyncThunk(
  "myProject/getTasksStatus",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projectdashboard",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(tasksStatus(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(tasksStatus([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getUpComingDeadlines = createAsyncThunk(
  "myProject/getUpComingDeadlines",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projectupcomingtasks",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(UpComingDeadlines(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(UpComingDeadlines([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getOverDueTasks = createAsyncThunk(
  "myProject/getOverDueTasks",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projectoverduetasks",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(overDueTasks(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(overDueTasks([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getProjects = createAsyncThunk(
  "myProject/getProjects",
  async (params, { dispatch, getState }) => {
    dispatch(alreadyRequestSent(true))
    const previousData = getState().myProjects.projects
    params.Page === 1
      ? dispatch(loadingStatus(true))
      : dispatch(loadingStatusLoadmore(true))
    const response = await httpService(
      "GET",
      "/projects/myprojectslist",
      "",
      params
    )
    if (response.status === 200) {
      const pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }

      response?.data?.pageNumber === 1
        ? dispatch(allProjects(response?.data?.data))
        : dispatch(allProjects([...previousData, ...response?.data?.data]))

      dispatch(dataCounts(pageCount))
      dispatch(loadingStatus(false))
      dispatch(loadingStatusLoadmore(false))
      dispatch(alreadyRequestSent(false))

      return true
    } else {
      dispatch(allProjects([]))
      dispatch(alreadyRequestSent(false))
      dispatch(dataCounts({}))
      dispatch(loadingStatus(false))
      dispatch(loadingStatusLoadmore(false))
      return false
    }
  }
)
export default myProjectSlice.reducer
