// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"

export const templatesListSlice = createSlice({
  name: "templatesListData",
  initialState: {
    templatesListData: [],
    templateSpinner: false,
    getTemplateGroupList: [],
    addTemplateLoadingStatus: false,
    templateTaskList: [],
    templatesDataById: {},
    loader: false,
    listLoader: true,
    editTaskGroupSpinner: false,
    addTaskGroupSpinner: false,
    deleteTaskGroupSpinner: false,
    templatesDataByIdLoadingStatus: false,
    templateDDL: [],
    fileUploadingStatus: false,
    addTemplateTaskLoadingStatus: false,
    editDrawer: false
  },

  reducers: {
    templatesListData: (state, action) => {
      state.templatesListData = action.payload
    },
    templateSpinner: (state, action) => {
      state.templateSpinner = action.payload
    },

    getTemplateGroupList: (state, action) => {
      state.getTemplateGroupList = action.payload
    },
    getTemplateTaskList: (state, action) => {
      state.templateTaskList = action.payload
    },
    templatesDataById: (state, action) => {
      state.templatesDataById = action.payload
    },
    templatesDataByIdSpinner: (state, action) => {
      state.templatesDataByIdLoadingStatus = action.payload
    },
    addTaskGroupSpinner: (state, action) => {
      state.addTaskGroupSpinner = action.payload
    },
    loadingStatus: (state, action) => {
      state.loader = action.payload
    },
    handleTemplateDDL: (state, action) => {
      state.templateDDL = action.payload
    },
    listLoaderStatus: (state, action) => {
      state.listLoader = action.payload
    },
    fileUploading: (state, action) => {
      state.fileUploadingStatus = action.payload
    },
    addTemplateLoadingStatus: (state, action) => {
      state.addTemplateLoadingStatus = action.payload
    },
    addTemplateTaskLoadingStatus: (state, action) => {
      state.addTemplateTaskLoadingStatus = action.payload
    },
    setedit: (state, action) => {
      state.editDrawer = action.payload
    }
  }
})

export const {
  fileUploading,
  templatesListData,
  templateSpinner,
  handleTemplateDDL,
  deleteTaskGroupSpinner,
  editTaskGroupSpinner,
  templatesDataByIdSpinner,
  templatesDataById,
  getTemplateGroupList,
  getTemplateTaskList,
  loadingStatus,
  taskGroupsData,
  listLoaderStatus,
  addTaskGroupSpinner,
  addTemplateLoadingStatus,
  addTemplateTaskLoadingStatus,
  setedit
} = templatesListSlice.actions
export default templatesListSlice.reducer

export const getTemplatesData = createAsyncThunk(
  "Templates/customtemplateslist",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "GET",
      "Templates/customtemplateslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      dispatch(templatesListData(response?.data?.data))
    } else {
      dispatch(loadingStatus(false))
      dispatch(templatesListData([]))
    }
  }
)

export const getTemplateDataById = createAsyncThunk(
  "Templates/getcustomtemplatebyid",
  async (params, { dispatch }) => {
    dispatch(templatesDataByIdSpinner(true))
    const response = await httpService(
      "GET",
      "Templates/getcustomtemplatebyid",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(templatesDataByIdSpinner(false))
      dispatch(templatesDataById(response?.data?.data))
    } else {
      dispatch(templatesDataByIdSpinner(false))
      dispatch(templatesDataById({}))
    }
  }
)

export const getTemplateGroupListData = createAsyncThunk(
  "gettemplategrouplist/getTemplateGroupListData",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "Templates/GetCustomTemplateTaskGroups",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getTemplateGroupList(response?.data?.data))
    } else {
      dispatch(getTemplateGroupList([]))
    }
  }
)
export const getTemplateTaskListData = createAsyncThunk(
  "Templates/getcustomtemplatestaskslist",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "Templates/getcustomtemplatestaskslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getTemplateTaskList(response?.data?.data))
    } else {
      dispatch(getTemplateTaskList([]))
    }
  }
)

export const getAllTemplateDDLs = createAsyncThunk(
  "Templates/getallddls",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "Templates/getallddls",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(handleTemplateDDL(response?.data))
    } else {
      dispatch(handleTemplateDDL({}))
    }
  }
)

export const addTemplate = createAsyncThunk(
  "template/addTemplate",
  async (body, { getState, dispatch }) => {
    dispatch(addTemplateLoadingStatus(true))
    const response = await httpService(
      "POST",
      "Templates/addcustomtemplate",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(addTemplateLoadingStatus(false))
      Toaster("success", "Template Added Successfully", "Success")
      const allTemplatesData = getState().customTemplates.templatesListData
      const newListState = [...allTemplatesData, response?.data?.data]
      dispatch(templatesListData(newListState))
      return { status: true, ...response?.data?.data }
    } else {
      dispatch(addTemplateLoadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
      return { status: false }
    }
  }
)

export const addTemplateTaskGroup = createAsyncThunk(
  "Templates/AddCustomTemplateTaskGroup",
  async (body, { getState, dispatch }) => {
    dispatch(addTaskGroupSpinner(true))
    const response = await httpService(
      "POST",
      "Templates/AddCustomTemplateTaskGroup",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(addTaskGroupSpinner(false))
      Toaster("success", "Task Group Added Successfully", "Success")
      const allTemplateTaskGroupData =
        getState().customTemplates.getTemplateGroupList
      const newListState = [
        ...allTemplateTaskGroupData,
        ...response?.data?.data
      ]
      dispatch(getTemplateGroupList(newListState))
      return true
    } else {
      dispatch(addTaskGroupSpinner(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
      return false
    }
  }
)

export const updateTemplateTaskGroup = createAsyncThunk(
  "Templates/EditCustomTemplateTaskGroup",
  async (body, { getState, dispatch }) => {
    dispatch(addTaskGroupSpinner(true))
    const response = await httpService(
      "POST",
      "Templates/EditCustomTemplateTaskGroup",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(addTaskGroupSpinner(false))
      Toaster("success", "Task Group Updated Successfully", "Success")
      const { getTemplateGroupList } = getState().customTemplates
      const allTemplateTaskGroupData = [...getTemplateGroupList]
      const index = allTemplateTaskGroupData.findIndex(
        item => item.takGroupId === response?.data?.data?.takGroupId
      )
      if (index > -1) {
        allTemplateTaskGroupData.splice(index, 1, response?.data?.data)
      }
      dispatch(getTemplateGroupList(allTemplateTaskGroupData))
      return true
    } else {
      dispatch(addTaskGroupSpinner(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
      return false
    }
  }
)

export const deleteTemplateTaskGroup = createAsyncThunk(
  "Templates/DeleteSingleCustomTemplateTaskGroup",
  async (params, { getState, dispatch }) => {
    const response = await httpService(
      "DELETE",
      "Templates/DeleteSingleCustomTemplateTaskGroup",
      "",
      params
    )
    if (response?.status === 200) {
      Toaster("success", "Task Group Deleted Successfully", "Success")
      const { getTemplateGroupList } = getState().customTemplates
      const allTemplateTaskGroupData = [...getTemplateGroupList]
      const index = allTemplateTaskGroupData.findIndex(
        item => item.takGroupId === params?.TaskGroupId
      )
      if (index > -1) {
        allTemplateTaskGroupData.splice(index, 1)
      }
      dispatch(getTemplateGroupList(allTemplateTaskGroupData))
      return true
    } else {
      Toaster("error", "Something went wrong please try again.", "Failed")
      return false
    }
  }
)

export const addTemplateTask = createAsyncThunk(
  "Templates/addcustomtemplatetask",
  async (body, { getState, dispatch }) => {
    dispatch(addTemplateTaskLoadingStatus(true))
    const response = await httpService(
      "POST",
      "Templates/addcustomtemplatetask",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(addTemplateTaskLoadingStatus(false))
      Toaster("success", "Task Group Added Successfully", "Success")
      const allTemplateTaskData = getState().customTemplates.templateTaskList
      const newListState = [...allTemplateTaskData, response?.data?.data]
      dispatch(getTemplateTaskList(newListState))
      return true
    } else {
      dispatch(addTemplateTaskLoadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
      return false
    }
  }
)

export const updateTemplateTask = createAsyncThunk(
  "Templates/editcustomtemplatetask",
  async (body, { getState, dispatch }) => {
    dispatch(addTemplateTaskLoadingStatus(true))
    const response = await httpService(
      "POST",
      "Templates/editcustomtemplatetask",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(addTemplateTaskLoadingStatus(false))
      Toaster("success", "Task updated Successfully", "Success")
      const templateTaskList = getState().customTemplates.templateTaskList
      const allTemplateTaskListCopy = [...templateTaskList]
      const index = allTemplateTaskListCopy.findIndex(
        item => item.taskId === response?.data?.data?.taskId
      )
      if (index > -1) {
        allTemplateTaskListCopy.splice(index, 1, response?.data?.data)
      }
      dispatch(getTemplateTaskList(allTemplateTaskListCopy))

      return true
    } else {
      dispatch(addTemplateTaskLoadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
      return false
    }
  }
)

export const updateTemplate = createAsyncThunk(
  "Templates/editcustomtemplate",
  async (body, { getState, dispatch }) => {
    dispatch(addTemplateLoadingStatus(true))

    const response = await httpService(
      "POST",
      "Templates/editcustomtemplate",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(addTemplateLoadingStatus(false))
      const allTemplatesData = getState().customTemplates.templatesListData
      const allTemplatesDataCopy = [...allTemplatesData]
      const index = allTemplatesDataCopy.findIndex(
        item => item.templateId === response?.data?.data?.templateId
      )

      if (index > -1) {
        allTemplatesDataCopy.splice(index, 1, response?.data?.data)
      }
      dispatch(templatesListData(allTemplatesDataCopy))
      Toaster("success", "Template Edit Successfully", "Success")
      return { status: true, ...response?.data?.data }
    } else {
      dispatch(addTemplateLoadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
      return { status: false }
    }
  }
)

export const UploadFile = createAsyncThunk(
  "Templates/uploadfile",
  async (body, { dispatch }) => {
    dispatch(fileUploading(true))
    const response = await httpService("POST", "Templates/uploadfile", body, "")
    if (response?.status === 200) {
      dispatch(fileUploading(false))
      Toaster("success", "Image Uploaded Successfully", "Success")
      return {
        status: true,
        data: response.data
      }
    } else {
      dispatch(fileUploading(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
      return {
        status: false
      }
    }
  }
)
