// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"

export const dashboardSlice = createSlice({
  name: "employeeData",
  initialState: {
    topAttendance: [],
    topTaskData: [],
    tasksAllCount: {},
    countLoading: false,
    topTaskLoading: false,
    topAttendanceLoading: false
  },
  reducers: {
    getTopAttendances: (state, action) => {
      state.topAttendance = action.payload
    },
    getTopTask: (state, action) => {
      state.topTaskData = action.payload
    },
    getAllTaskCount: (state, action) => {
      state.tasksAllCount = action.payload
    },
    getCountLoading: (state, action) => {
      state.countLoading = action.payload
    },
    getTopTaskLoading: (state, action) => {
      state.topTaskLoading = action.payload
    },
    getTopAttendancesLoading: (state, action) => {
      state.topAttendanceLoading = action.payload
    }
  }
})
export const {
  getTopAttendances,
  getTopTask,
  getAllTaskCount,
  getCountLoading,
  getTopTaskLoading,
  getTopAttendancesLoading
} = dashboardSlice.actions
export default dashboardSlice.reducer

export const getTopAttendance = createAsyncThunk(
  "dashboard/attendance",
  async (params, { dispatch }) => {
    dispatch(getTopAttendancesLoading(true))
    const param = {
      fromDate: params?.start,
      toDate: params?.end
    }
    const response = await httpService(
      "GET",
      "/MyTasks/getmyattendancesheet",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(getTopAttendances(response?.data?.data))
      dispatch(getTopAttendancesLoading(false))
    } else {
      dispatch(getTopAttendances([]))
      dispatch(getTopAttendancesLoading(false))
    }
  }
)
export const getTopTasks = createAsyncThunk(
  "dashboard/getTopTasks",
  async (params, { dispatch }) => {
    dispatch(getTopTaskLoading(true))
    const param = {
      fromDate: params?.start,
      toDate: params?.end
    }
    const response = await httpService("GET", "/Dashboard/toptasks", "", param)
    if (response.status === 200) {
      dispatch(getTopTask(response?.data?.data))
      dispatch(getTopTaskLoading(false))
    } else {
      dispatch(getTopTask([]))
      dispatch(getTopTaskLoading(false))
    }
  }
)
export const getAllTasksCount = createAsyncThunk(
  "Dashboard/alltaskscount",
  async (param, { dispatch }) => {
    dispatch(getCountLoading(true))
    const response = await httpService(
      "GET",
      `/Dashboard/alltaskscount`,
      "",
      param
    )
    if (response.status === 200) {
      dispatch(getAllTaskCount(response?.data?.data))
      dispatch(getCountLoading(false))
    } else {
      dispatch(getAllTaskCount({}))
      dispatch(getCountLoading(false))
    }
  }
)
