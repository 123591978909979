// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// ** Dependencies Imports
export const reportMyTeam = createSlice({
  name: "reportMyTeam",
  initialState: {
    reportMyTeamFiltersDDL: {},
    data: [],
    topEmployee: [],
    cardsData: [],
    topTasks: [],
    cardsDataLoading: false,
    topEmployeeLoading: false,
    topTaskLoading: false
  },
  reducers: {
    topEmployee: (state, action) => {
      state.topEmployee = action.payload
    },
    topTasks: (state, action) => {
      state.topTasks = action.payload
    },
    cardsData: (state, action) => {
      state.cardsData = action.payload
    },
    loadingStatus: (state, action) => {
      state.taskLoading = action.payload
    },
    reportMyTeamFilters: (state, action) => {
      state.reportMyTeamFiltersDDL = action.payload
    },
    getCardsDataLoading: (state, action) => {
      state.cardsDataLoading = action.payload
    },
    getTopEmployeeLoading: (state, action) => {
      state.topEmployeeLoading = action.payload
    },
    getTopTaskLoading: (state, action) => {
      state.topTaskLoading = action.payload
    }
  }
})

export const {
  topEmployee,
  topTasks,
  cardsData,
  reportMyTeamFilters,
  getCardsDataLoading,
  getTopEmployeeLoading,
  getTopTaskLoading
} = reportMyTeam.actions
export const getreportTopEmployee = createAsyncThunk(
  "reportMyTeam/getReportTopEmployee",
  async (params, { dispatch }) => {
    dispatch(getTopEmployeeLoading(true))
    const response = await httpService(
      "GET",
      `/Dashboard/topemployees`,
      "",
      params
    )
    if (response.status === 200) {
      dispatch(topEmployee(response?.data?.data))
      dispatch(getTopEmployeeLoading(false))
    } else {
      dispatch(topEmployee([]))
      dispatch(getTopEmployeeLoading(false))
    }
  }
)
export const getreportTopTasks = createAsyncThunk(
  "reportMyTeam/getreportTopTasks",
  async (params, { dispatch }) => {
    dispatch(getTopTaskLoading(true))
    const response = await httpService("GET", `/Dashboard/toptasks`, "", params)
    if (response.status === 200) {
      dispatch(topTasks(response?.data?.data))
      dispatch(getTopTaskLoading(false))
    } else {
      dispatch(topTasks([]))
      dispatch(getTopTaskLoading(false))
    }
  }
)
export const getReportsCardsData = createAsyncThunk(
  "reportMyTeam/getReportsCardsData",
  async (params, { dispatch }) => {
    dispatch(getCardsDataLoading(true))
    const response = await httpService(
      "GET",
      "Dashboard/teamstatus",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(cardsData(response?.data))
      dispatch(getCardsDataLoading(false))
    } else {
      dispatch(cardsData([]))
      dispatch(getCardsDataLoading(false))
    }
  }
)
export const getreportMyTaskFilter = createAsyncThunk(
  "reportMyTeam/getReportMyTeamFilterData",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/ActivityLog/getfilters",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(reportMyTeamFilters(response?.data))
    } else {
      dispatch(reportMyTeamFilters({}))
    }
  }
)
export default reportMyTeam.reducer
