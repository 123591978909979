// ** Redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// ** Dependencies Imports
import Toaster from "@modules/Toaster"
import { taskHandler } from "@src/signalR/utils"
// ** Loadash
import _ from "lodash"
import { convertToLower } from "../components/TaskBoard/parseData"
import { getDataFromLocalStorage } from "@utils"
import {
  AllowAddTaskInTBScreen,
  getSrcDestIndex,
  getUpdatedGroupState
} from "../Utils"

const searchTaskFilter =
  JSON.parse(localStorage.getItem("searchTaskFilter")) || {}
export const appTodoSlice = createSlice({
  name: "appTodo",
  initialState: {
    taskTitle: "Add",
    alreadyRequestSent: false,
    isAttendanceStop: false,
    commentLoadingStatus: false,
    activityLoadingStatus: false,
    attachmentsLoadingStatus: false,
    selectedTaskLoadingStatus: false,
    tasksActiveTabStatus: 5,
    projectSingleDDL: {},
    taskGroupSingleDDL: {},
    tasks: [],
    pageCount: {},
    selectedTask: {},
    addTaskDDL: {},
    teamTaskFiltersDDL: {},
    usersByProject: [],
    teamsByProject: [],
    taskCount: {},
    files: [],
    taskLoading: false,
    teamFilterLoading: false,
    detailLoading: false,
    taskDetails: {},
    taskActivites: [],
    taskAttachments: [],
    taskComments: [],
    tasksGroupDDL: [],
    addTaskLoadingStatus: false,
    editTaskLoadingStatus: false,
    updatePlannedDateKey: false,
    closeRecurrenceToggle: true,
    tasksUrl: "TeamTasks/gettaskslist",
    updateTaskQuicklyLoading: false,
    previewTemplateData: [],
    loadingPreviewTemplateTasks: false,
    params: {
      filter: "",
      q: "",
      sort: "",
      tag: ""
    },
    templateData: [],
    loadingTemplateData: false,
    taskGroupByProjectIdStatus: false,
    usersByProjectsStatus: false,
    teamByProjectsStatus: false,
    taskPlannedDuration: {},
    taskCountLoader: false,
    taskBoardData: null,
    exportJSONTasks: [],
    exportTaskLoader: false,
    manualTaskActivityLoader: false,
    searchFilter: {
      projectId: searchTaskFilter?.projectid || null,
      employee: searchTaskFilter?.userid || null,
      myTasks: searchTaskFilter?.myTask ? true : false
    },
    subtasksList: [],
    subtaskLoading: false,
    taskActiveKey: null,
    detailSubtaskList: [],
    selectedSubtask: null,
    selectedSubtaskModalType: null,
    markCheckListLoader: false,
    verifyTaskLoader: false
  },
  reducers: {
    markCheckListLoader: (state, action) => {
      state.markCheckListLoader = action.payload
    },
    verifyTaskLoader: (state, action) => {
      state.verifyTaskLoader = action.payload
    },
    closeRecurrenceToggle: (state, action) => {
      state.closeRecurrenceToggle = action.payload
    },
    manualTaskActivityLoader: (state, action) => {
      state.manualTaskActivityLoader = action.payload
    },
    teamsByProjectsStatus: (state, action) => {
      state.teamByProjectsStatus = action.payload
    },
    getExportJSONTasks: (state, action) => {
      state.exportJSONTasks = action.payload
    },
    exportTaskLoader: (state, action) => {
      state.exportTaskLoader = action.payload
    },
    usersByProjectsStatus: (state, action) => {
      state.usersByProjectsStatus = action.payload
    },
    handleTasksActiveTabStatus: (state, action) => {
      state.tasksActiveTabStatus = action.payload
    },
    handleTaskTitle: (state, action) => {
      state.taskTitle = action.payload
    },
    taskGroupByProjectIdStatus: (state, action) => {
      state.taskGroupByProjectIdStatus = action.payload
    },
    loadingTemplateData: (state, action) => {
      state.loadingTemplateData = action.payload
    },
    templateData: (state, action) => {
      state.templateData = action.payload
    },
    handlepreviewTemplateData: (state, action) => {
      state.previewTemplateData = action.payload
    },
    handleIsAttendanceStop: (state, action) => {
      state.isAttendanceStop = action.payload
    },
    reOrderTasks: (state, action) => {
      state.tasks = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    },
    selectTaskLoading: (state, action) => {
      state.selectedTaskLoadingStatus = action.payload
    },
    taskCounts: (state, action) => {
      state.taskCount = action.payload
    },
    loadingStatus: (state, action) => {
      state.taskLoading = action.payload
    },
    teamFilterLoadingStatus: (state, action) => {
      state.teamFilterLoading = action.payload
    },
    loadMoreStatus: (state, action) => {
      state.loadMoreStatus = action.payload
    },
    getAddTaskDDL: (state, action) => {
      state.addTaskDDL = action.payload
    },
    teamTaskFilters: (state, action) => {
      state.teamTaskFiltersDDL = action.payload
    },
    uploadFiles: (state, action) => {
      state.files = action.payload
    },
    usersByProjectId: (state, action) => {
      state.usersByProject = action.payload
    },
    teamsByProjectId: (state, action) => {
      state.teamsByProject = action.payload
    },
    changeTasksListUrl: (state, action) => {
      state.tasksUrl = action.payload
    },
    handlePageCount: (state, action) => {
      state.pageCount = action.payload
    },
    getTaskDetail: (state, action) => {
      state.taskDetails = action.payload
    },
    getTaskActivities: (state, action) => {
      state.taskActivites = action.payload
    },
    getTaskAttachments: (state, action) => {
      state.taskAttachments = action.payload
    },
    getTaskComments: (state, action) => {
      state.taskComments = action.payload
    },
    detailLodingStatus: (state, action) => {
      state.detailLoading = action.payload
    },
    taskGroupDDL: (state, action) => {
      state.tasksGroupDDL = action.payload
    },
    handleProjectSingleDDL: (state, action) => {
      state.projectSingleDDL = action.payload
    },
    handleTaskGroupSingleDDL: (state, action) => {
      state.taskGroupSingleDDL = action.payload
    },
    handleAddTaskLoadingStatus: (state, action) => {
      state.addTaskLoadingStatus = action.payload
    },
    handleEditTaskLoadingStatus: (state, action) => {
      state.editTaskLoadingStatus = action.payload
    },
    commentsLoading: (state, action) => {
      state.commentLoadingStatus = action.payload
    },
    activitiesLoading: (state, action) => {
      state.activityLoadingStatus = action.payload
    },
    attachmentsLoading: (state, action) => {
      state.attachmentsLoadingStatus = action.payload
    },
    handleAlreadyRequested: (state, action) => {
      state.alreadyRequestSent = action.payload
    },
    handleUpdateTaskQuicklyLoading: (state, action) => {
      state.updateTaskQuicklyLoading = action.payload
    },
    handleTaskPlannedDuration: (state, action) => {
      state.taskPlannedDuration = action.payload
    },
    taskCountLoader: (state, action) => {
      state.taskCountLoader = action.payload
    },
    loadingPreviewTemplateTasks: (state, action) => {
      state.loadingPreviewTemplateTasks = action.payload
    },
    taskBoardTasks: (state, action) => {
      state.taskBoardData = action.payload
    },
    taskActiveKey: (state, action) => {
      state.taskActiveKey = action.payload
    },
    updatePlannedDate: (state, action) => {
      state.updatePlannedDateKey = action.payload
    },
    addLoadMoreDataToGroups: (state, action) => {
      let newState = current(state.taskBoardData)
      let index
      if (action.payload.groupby === "status") {
        index = newState.groups.findIndex(
          item => item.apiKey === action.payload.status
        )
      } else if (action.payload.groupby === "strPriority") {
        index = newState.groups.findIndex(
          item => item.apiKey === action.payload.priority
        )
      } else if (action.payload.groupby === "projectId") {
        index = newState?.groups.findIndex(
          item => item.apiKey === action.payload.projectid
        )
      } else if (action.payload.groupby === "taskType") {
        if (action.payload.taskGroup) {
          index = newState.groups.findIndex(
            item => item.apiKey === action.payload.taskGroup
          )
        } else {
          index = newState.groups.length - 1
        }
      }

      const groups = [...newState.groups]
      let taskIds
      let updatedGroup
      if (index !== -1) {
        taskIds = action.payload.data.map(item => item.taskId)
        let tasks = [...newState.groups[index].taskIds, ...taskIds]
        tasks = _.uniq(tasks)
        updatedGroup = {
          ...newState.groups[index],
          taskIds: tasks
        }
      }
      groups.splice(index, 1, updatedGroup)
      let allTasks = [...newState.tasks, ...action.payload.data]
      allTasks = _.uniqBy(allTasks, "taskId")
      newState = {
        ...newState,
        groups,
        tasks: allTasks
      }
      state.taskBoardData = newState
    },
    addTaskTaskBoard: (state, action) => {
      let newState = current(state.taskBoardData)
      const index = action.payload.index
      const groups = [...newState.groups]
      let updatedGroup
      if (index !== -1) {
        let tasks = [
          action.payload.data.taskId,
          ...newState.groups[index].taskIds
        ]
        tasks = _.uniq(tasks)
        updatedGroup = {
          ...newState.groups[index],
          taskIds: tasks,
          count: newState.groups[index].count
            ? newState.groups[index].count + 1
            : 1
        }
      }
      groups.splice(index, 1, updatedGroup)
      let allTasks = [action.payload.data, ...newState.tasks]
      allTasks = _.uniqBy(allTasks, "taskId")
      newState = {
        ...newState,
        groups,
        tasks: allTasks
      }
      state.taskBoardData = newState
    },
    addTaskGroup: (state, action) => {
      if (state.taskBoardData) {
        let newState = current(state.taskBoardData)

        const groups = [...newState.groups]
        const newTaskGroup = {
          apiKey: action.payload.taskTypeId,
          id: convertToLower(action.payload.taskType1),
          title: action.payload.taskType1,
          taskIds: []
        }
        const newGroupOrder = [...newState.groupOrder]
        newGroupOrder.splice(newGroupOrder.length - 1, 0, newTaskGroup.title)
        groups.splice(groups.length - 1, 0, newTaskGroup)

        newState = {
          ...newState,
          groups,
          groupOrder: newGroupOrder
        }
        state.taskBoardData = newState
      }
    },
    editTaskBoardTask: (state, action) => {
      if (state.taskBoardData) {
        let newState = current(state.taskBoardData)
        const newTask = action.payload.data
        const oldTask = newState.tasks.find(
          item => item.taskId === newTask.taskId
        )
        const index = getSrcDestIndex(action, oldTask, newTask, newState)

        let tasks = [...newState.tasks]
        tasks = tasks.map(item => {
          return item.taskId === action.payload.data.taskId
            ? action.payload.data
            : item
        })
        newState = {
          ...newState,
          tasks
        }
        if (index[0] === index[1]) {
          state.taskBoardData = newState
        } else {
          newState = getUpdatedGroupState(
            index[0],
            index[1],
            newState,
            newTask,
            oldTask
          )

          state.taskBoardData = newState
        }
      }
    },
    updateSearchFilter: (state, action) => {
      state.searchFilter = action.payload
    },
    subtaskList: (state, action) => {
      state.subtasksList = action?.payload
    },
    updateDetailSubtaskList: (state, action) => {
      state.detailSubtaskList = action?.payload
    },
    updateSubtaskLoading: (state, action) => {
      state.subtaskLoading = action.payload
    },
    selectSubtask: (state, action) => {
      state.selectedSubtask = action?.payload
    },
    setSubtaskModalType: (state, action) => {
      state.selectedSubtaskModalType = action?.payload
    }
  }
})
export const {
  setSubtaskModalType,
  handleTasksActiveTabStatus,
  markCheckListLoader,
  manualTaskActivityLoader,
  teamsByProjectId,
  teamsByProjectsStatus,
  handleTaskPlannedDuration,
  taskCountLoader,
  taskCounts,
  handleTaskTitle,
  usersByProjectsStatus,
  taskGroupByProjectIdStatus,
  loadingTemplateData,
  loadingPreviewTemplateTasks,
  templateData,
  handlepreviewTemplateData,
  handleUpdateTaskQuicklyLoading,
  selectTaskLoading,
  handleAlreadyRequested,
  handleIsAttendanceStop,
  handleAddTaskLoadingStatus,
  handleEditTaskLoadingStatus,
  activitiesLoading,
  attachmentsLoading,
  closeRecurrenceToggle,
  updatePlannedDate,
  reOrderTasks,
  selectTask,
  getAddTaskDDL,
  teamTaskFilters,
  loadingStatus,
  teamFilterLoadingStatus,
  detailLodingStatus,
  usersByProjectId,
  uploadFiles,
  changeTasksListUrl,
  handlePageCount,
  getTaskDetail,
  getTaskActivities,
  getTaskAttachments,
  taskGroupDDL,
  handleProjectSingleDDL,
  handleTaskGroupSingleDDL,
  getTaskComments,
  commentsLoading,
  loadMoreStatus,
  taskBoardTasks,
  addTaskTaskBoard,
  addLoadMoreDataToGroups,
  addTaskGroup,
  taskActiveKey,
  getExportJSONTasks,
  exportTaskLoader,
  editTaskBoardTask,
  updateSearchFilter,
  subtaskList,
  selectSubtask,
  updateSubtaskLoading,
  verifyTaskLoader,
  updateDetailSubtaskList
} = appTodoSlice.actions
export const getTasks = createAsyncThunk(
  "appTodo/getTasks",
  async (params, { dispatch, getState }) => {
    dispatch(handleAlreadyRequested(true))
    dispatch(handleTasksActiveTabStatus(params?.status))
    const previousData = getState().myTasks.tasks
    params.pageno === 1
      ? dispatch(loadingStatus(true))
      : dispatch(loadMoreStatus(true))
    const response = await httpService(
      "GET",
      getState().myTasks.tasksUrl,
      "",
      params
    )
    if (response.status === 200) {
      const pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }
      dispatch(
        reOrderTasks(
          params.pageno > 1
            ? [...previousData, ...response?.data?.data]
            : response?.data?.data
        )
      )

      if (params.pageno === 1 && params.viewtype === "Board") {
        dispatch(taskBoardTasks(null))
      } else if (params.viewtype === "Board") {
        dispatch(
          addLoadMoreDataToGroups({ ...params, data: response.data.data })
        )
      }
      dispatch(handlePageCount(pageCount))
      dispatch(loadingStatus(false))
      dispatch(handleAlreadyRequested(false))
      dispatch(loadMoreStatus(false))
    } else {
      dispatch(handlePageCount({}))
      dispatch(reOrderTasks([]))
      dispatch(loadingStatus(false))
      dispatch(handleAlreadyRequested(false))
      dispatch(loadMoreStatus(false))
    }
    return response.status === 200 ? true : false
  }
)

export const markCheckList = createAsyncThunk(
  "TeamTasks/markCheckList",
  async (params, { dispatch, getState }) => {
    const previousData = { ...getState()?.myTasks?.taskDetails }
    const checkList = [...previousData.checklist]
    dispatch(markCheckListLoader(true))
    const response = await httpService(
      "POST",
      "TeamTasks/UpdateCheckListItem",
      params,
      ""
    )
    if (response?.status === 200) {
      const index = checkList?.findIndex(
        data => data?.checklistId === params?.checklistId
      )
      checkList[index] = response?.data?.data
      dispatch(getTaskDetail({ ...previousData, checklist: checkList }))
      Toaster("success", `Checklist status is updated successfully`, "Success")
      dispatch(markCheckListLoader(false))
      return true
    } else {
      dispatch(markCheckListLoader(false))
    }
  }
)
export const verifyTask = createAsyncThunk(
  "TeamTasks/verifyTask",
  async (params, { dispatch, getState }) => {
    dispatch(verifyTaskLoader(true))
    const allTasksData = getState().myTasks
    const previousTaskListData = [...allTasksData?.tasks]
    let previousBoardData = {}
    let previousTaskBoardData = []
    if (allTasksData?.taskBoardData?.tasks?.length > 0) {
      previousBoardData = { ...allTasksData?.taskBoardData }
      previousTaskBoardData = [...previousBoardData?.tasks]
    }

    const { isVerified, taskId, verifiedComments } = params
    const param = { isVerified, taskId, verifiedComments }
    const response = await httpService(
      "POST",
      "TeamTasks/verifytask",
      param,
      ""
    )
    if (response?.status === 200) {
      // ** handle for List view
      const listViewIndex = previousTaskListData?.findIndex(
        data => data?.taskId === taskId
      )
      if (params?.taskStatus === 999) {
        previousTaskListData.splice(listViewIndex, 1)
        dispatch(reOrderTasks(previousTaskListData))
      } else {
        previousTaskListData[listViewIndex] = response?.data?.data
        dispatch(reOrderTasks(previousTaskListData))
      }
      // ** handle for Board and Kanban view
      if (previousTaskBoardData.length > 0) {
        const boardViewIndex = previousTaskBoardData?.findIndex(
          data => data?.taskId === taskId
        )
        previousTaskBoardData[boardViewIndex] = response?.data?.data
        dispatch(
          taskBoardTasks({ ...previousBoardData, tasks: previousTaskBoardData })
        )
      }
      Toaster("success", "Task is marked as verified successfully", "Success")
      dispatch(verifyTaskLoader(false))
      return true
    } else {
      dispatch(verifyTaskLoader(false))
    }
  }
)
export const addManualTaskActivity = createAsyncThunk(
  "TeamTasks/addManualTaskActivity",
  async (params, { dispatch }) => {
    dispatch(manualTaskActivityLoader(true))
    const response = await httpService(
      "POST",
      "TeamTasks/addtaskactivity",
      params,
      ""
    )
    if (response?.status === 200) {
      Toaster("success", "Task Activity Added Successfully", "Success")
      dispatch(manualTaskActivityLoader(false))
      return true
    } else {
      dispatch(manualTaskActivityLoader(false))
    }
  }
)
export const getExportTasks = createAsyncThunk(
  "TeamTasks/getExportTasks",
  async (params, { dispatch }) => {
    dispatch(exportTaskLoader(true))
    const response = await httpService(
      "GET",
      "TeamTasks/getexporttaskslist",
      "",
      params
    )
    if (response?.status === 200) {
      const csvParams = [
        ...params?.csvColumns,
        { name: "project_Name", checked: true },
        { name: "task_Name", checked: true }
      ]
      const filteredArray = response?.data?.data.map(item => {
        const filteredObject = {}
        csvParams.forEach(obj => {
          if (item.hasOwnProperty(obj.name)) {
            filteredObject[obj.name] = item[obj.name]
          }
        })
        return filteredObject
      })
      dispatch(getExportJSONTasks(filteredArray))
      dispatch(exportTaskLoader(false))
    } else {
      dispatch(exportTaskLoader(false))
      dispatch(getExportJSONTasks([]))
    }
  }
)
export const getTaskCounts = createAsyncThunk(
  "TeamTasks/getTaskCounts",
  async (params, { dispatch }) => {
    dispatch(taskCountLoader(true))
    const response = await httpService(
      "GET",
      "/TeamTasks/gettaskscount",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(taskCountLoader(false))
      dispatch(taskCounts(response?.data?.data))
      dispatch(taskBoardTasks(null))
    } else {
      dispatch(taskCountLoader(false))
      dispatch(taskCounts({}))
    }
  }
)
export const getAddTask = createAsyncThunk(
  "TeamTasks/getAddTask",
  async (params, { dispatch }) => {
    const response = await httpService("GET", "/TeamTasks/addtask", "", params)
    if (response?.status === 200) {
      dispatch(getAddTaskDDL(response?.data))
    } else {
      dispatch(getAddTaskDDL({}))
    }
  }
)
export const getTaskGroupDDLByProjectId = createAsyncThunk(
  "taskGroupTable/getTaskGroupDDL",
  async (params, { dispatch }) => {
    dispatch(taskGroupByProjectIdStatus(true))
    const response = await httpService(
      "GET",
      `/TeamTasks/getTaskGroupsDDLByProjectID`,
      "",
      params
    )
    if (response.status === 200) {
      dispatch(taskGroupByProjectIdStatus(false))
      dispatch(taskGroupDDL(response?.data?.taskGroupsDDL))
    } else {
      dispatch(taskGroupByProjectIdStatus(false))
      dispatch(taskGroupDDL([]))
    }
  }
)
export const getEditTask = createAsyncThunk(
  "TeamTasks/getEditTask",
  async (params, { dispatch }) => {
    dispatch(selectTaskLoading(true))
    const response = await httpService("GET", "/TeamTasks/edittask", "", params)
    if (response?.status === 200) {
      dispatch(selectTaskLoading(false))
      dispatch(selectTask(response?.data?.data))
    } else {
      dispatch(selectTaskLoading(false))
      dispatch(selectTask({}))
    }
  }
)
export const getTaskDetailsById = createAsyncThunk(
  "TeamTasks/taskdetail",
  async (params, { dispatch }) => {
    dispatch(detailLodingStatus(true))
    const response = await httpService(
      "GET",
      "/TeamTasks/taskdetail",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(detailLodingStatus(false))
      dispatch(getTaskDetail(response?.data?.data))
      dispatch(selectSubtask(response.data.data))
      return true
    } else {
      dispatch(detailLodingStatus(false))
      dispatch(getTaskDetail({}))
      return false
    }
  }
)
export const updateTaskQuickly = createAsyncThunk(
  "TeamTasks/QuickUpdateTasks",
  async (body, { dispatch }) => {
    dispatch(handleUpdateTaskQuicklyLoading(true))
    dispatch(detailLodingStatus(true))
    const response = await httpService(
      "PUT",
      "/TeamTasks/QuickUpdateTasks",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(handleUpdateTaskQuicklyLoading(false))
      return true
    } else {
      dispatch(handleUpdateTaskQuicklyLoading(false))
      return false
    }
  }
)
export const updateTaskQuicklyGantt = createAsyncThunk(
  "TeamTasks/QuickUpdateTaskGantt",
  async (body, { dispatch }) => {
    dispatch(handleUpdateTaskQuicklyLoading(true))
    dispatch(detailLodingStatus(true))
    const response = await httpService(
      "PUT",
      "/TeamTasks/QuickUpdateTaskGantt",
      body,
      ""
    )
    if (response?.status === 200) {
      dispatch(handleUpdateTaskQuicklyLoading(false))
      return true
    } else {
      dispatch(handleUpdateTaskQuicklyLoading(false))
      return false
    }
  }
)
export const getTaskActivitesById = createAsyncThunk(
  "TeamTasks/taskactivity",
  async (params, { dispatch }) => {
    dispatch(activitiesLoading(true))
    const response = await httpService(
      "GET",
      "TeamTasks/taskactivity",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(activitiesLoading(false))
      dispatch(getTaskActivities(response?.data?.data))
    } else {
      dispatch(activitiesLoading(false))
      dispatch(getTaskActivities([]))
    }
  }
)
export const getTaskAttachmentsById = createAsyncThunk(
  "/TeamTasks/getattachments",
  async (params, { dispatch }) => {
    dispatch(attachmentsLoading(true))
    const response = await httpService(
      "GET",
      "TeamTasks/getattachments",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(attachmentsLoading(false))
      dispatch(getTaskAttachments(response?.data?.data))
    } else {
      dispatch(attachmentsLoading(false))
      dispatch(getTaskAttachments([]))
    }
  }
)
export const getTaskCommentsById = createAsyncThunk(
  "/TeamTasks/getattachments",
  async (params, { dispatch }) => {
    dispatch(commentsLoading(true))
    const response = await httpService(
      "GET",
      "/TeamTasks/getCommentsByTaskId",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(commentsLoading(false))
      dispatch(getTaskComments(response?.data?.taskComments))
    } else {
      dispatch(commentsLoading(false))
      dispatch(getTaskComments([]))
    }
  }
)
export const UploadFile = createAsyncThunk(
  "TeamTasks/UploadFile",
  async (body, { dispatch, getState }) => {
    const response = await httpService(
      "POST",
      "/TeamTasks/uploadfile",
      body?.formData,
      ""
    )
    if (response?.status === 200) {
      const previousFiles = getState()?.myTasks?.files
      const newFile = {
        filePath: response?.data?.filePath,
        name: body.file.name
      }
      const previousFilesCopy = [...previousFiles, newFile]
      dispatch(uploadFiles(previousFilesCopy))
      return {
        file: response.data,
        status: response?.status
      }
    } else {
      dispatch(uploadFiles([]))
      return {
        file: null,
        status: response?.status
      }
    }
  }
)
export const getUsersByProjects = createAsyncThunk(
  "TeamTasks/usersByproject",
  async (params, { dispatch }) => {
    dispatch(usersByProjectsStatus(true))
    const response = await httpService(
      "POST",
      "/TeamTasks/usersbyproject",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(usersByProjectsStatus(false))
      dispatch(usersByProjectId(response?.data?.projectTeamDDL))
    } else {
      dispatch(usersByProjectsStatus(false))
      dispatch(usersByProjectId([]))
    }
  }
)
export const getTeamsByProjects = createAsyncThunk(
  "TeamTasks/getTeamsByProjects",
  async (params, { dispatch }) => {
    dispatch(teamsByProjectsStatus(true))
    const response = await httpService(
      "GET",
      "/TeamTasks/getTeamsDDLByProjectID",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(teamsByProjectsStatus(false))
      dispatch(teamsByProjectId(response?.data?.teamsDDL))
    } else {
      dispatch(teamsByProjectsStatus(false))
      dispatch(teamsByProjectId([]))
    }
  }
)
export const getTeamFilters = createAsyncThunk(
  "appTodo/getAddTask",
  async (params, { dispatch }) => {
    dispatch(teamFilterLoadingStatus(true))
    const response = await httpService(
      "GET",
      "/TeamTasks/getteamfilters",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(teamTaskFilters(response?.data))
      dispatch(teamFilterLoadingStatus(false))
    } else {
      dispatch(teamTaskFilters({}))
      dispatch(teamFilterLoadingStatus(false))
    }
  }
)
export const getTaskPlannedDuration = createAsyncThunk(
  "appTodo/getAddTask",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/getPlannedDuration",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(handleTaskPlannedDuration(response?.data))
    } else {
      dispatch(handleTaskPlannedDuration({}))
    }
  }
)
export const addTask = createAsyncThunk(
  "appTodo/addTask",
  async (task, { dispatch, getState }) => {
    dispatch(handleAddTaskLoadingStatus(true))
    const previousData = getState()?.myTasks?.tasks
    const previousDataCopy = [...previousData]
    const { searchFilter, updatePlannedDateKey } = getState()?.myTasks
    const userData = getDataFromLocalStorage("userDataAuth")
    const response = await httpService("POST", "/TeamTasks/addtask", task, "")
    if (response?.status === 200) {
      if (task.parentTaskId) {
        const { subtasksList } = getState().myTasks
        const previousSubtasks = [...response?.data?.data, ...subtasksList]
        dispatch(subtaskList(previousSubtasks))
        dispatch(updateDetailSubtaskList(previousSubtasks))
      }
      const tasksActiveTabStatus = getState().myTasks?.tasksActiveTabStatus
      let finalData = []
      response?.data?.data?.forEach(item => {
        if (AllowAddTaskInTBScreen(searchFilter, item, userData)) {
          if (tasksActiveTabStatus === 0 || tasksActiveTabStatus === 5) {
            tasksActiveTabStatus === 5 && task?.status === 4
              ? ""
              : finalData.push(item)
          } else {
            tasksActiveTabStatus === task?.status ? finalData.push(item) : ""
          }
        }
      })

      finalData = [...finalData, ...previousDataCopy]
      dispatch(reOrderTasks(finalData))
      dispatch(taskBoardTasks(null))
      dispatch(handleAddTaskLoadingStatus(false))
      dispatch(handleTaskGroupSingleDDL({}))
      dispatch(handleProjectSingleDDL({}))
      dispatch(closeRecurrenceToggle(false))
      dispatch(updatePlannedDate(!updatePlannedDateKey))
      Toaster("success", "Task Added Successfully", "Success")
    } else {
      dispatch(handleAddTaskLoadingStatus(false))
      Toaster("error", response.data.response.data.message, "Failed")
    }
    return response?.status === 200 ? response.data : false
  }
)
export const deleteAttachmentByFileUrl = createAsyncThunk(
  "/TeamTasks/deleteattachmentwithurl",
  async (params, { dispatch, getState }) => {
    const previousData = getState().myTasks.taskAttachments
    const previousDataCopy = [...previousData]
    const fileIndex = previousData.findIndex(
      file => file.filePath === params.filepath
    )
    previousDataCopy.splice(fileIndex, 1)
    const response = await httpService(
      "POST",
      "/TeamTasks/deleteattachmentwithurl",
      "",
      params
    )
    if (response?.status === 200) {
      if (fileIndex > -1) {
        dispatch(getTaskAttachments(previousDataCopy))
      }
      Toaster("success", "File Deleted Successfully", "Success")
    } else {
      Toaster(
        "error",
        response?.data?.response?.data?.message
          ? response?.data?.response?.data?.message
          : "Something went wrong please try again.",
        "Failed"
      )
    }
    return response?.status === 200 ? true : false
  }
)
export const updateTask = createAsyncThunk(
  "appTodo/updateTask",
  async (task, { dispatch, getState }) => {
    dispatch(handleEditTaskLoadingStatus(true))
    const previousData = getState().myTasks.tasks
    const previousDataCopy = [...previousData]
    const fileIndex = previousData.findIndex(
      file => file.taskId === task.taskId
    )
    const { searchFilter } = getState()?.myTasks
    const groupBy = getState()?.taskView?.groupByKey
    const userData = getDataFromLocalStorage("userDataAuth")

    const response = await httpService("POST", "/TeamTasks/edittask", task, "")
    if (response?.status === 200) {
      const tasksActiveTabStatus = getState().myTasks.tasksActiveTabStatus
      if (task?.subtask) {
        const { subtasksList } = getState().myTasks
        const previousSubtasksList = subtasksList.map(item => {
          return item?.taskId === task?.taskId ? response?.data?.data : item
        })
        dispatch(subtaskList(previousSubtasksList))
        dispatch(updateDetailSubtaskList(previousSubtasksList))
        dispatch(selectSubtask(null))
      }

      if (AllowAddTaskInTBScreen(searchFilter, task, userData)) {
        if (tasksActiveTabStatus === 0 || tasksActiveTabStatus === 5) {
          tasksActiveTabStatus === 5 && task?.status === 4
            ? previousDataCopy.splice(fileIndex, 1)
            : previousDataCopy.splice(fileIndex, 1, response.data.data)
        } else {
          tasksActiveTabStatus === task?.status
            ? previousDataCopy.splice(fileIndex, 1, response.data.data)
            : previousDataCopy.splice(fileIndex, 1)
        }
        dispatch(reOrderTasks(previousDataCopy))
        dispatch(
          editTaskBoardTask({ groupby: groupBy, data: response.data.data })
        )
      } else {
        const updatedData = previousData.filter(
          item => item.taskId !== task.taskId
        )
        dispatch(reOrderTasks(updatedData))
        dispatch(taskBoardTasks(null))
      }

      dispatch(handleEditTaskLoadingStatus(false))
      Toaster("success", "Task Updated Successfully", "Success")
    } else {
      dispatch(handleEditTaskLoadingStatus(false))
      Toaster(
        "error",
        response?.data?.response?.data?.message
          ? response?.data?.response?.data?.message
          : "Something went wrong please try again.",
        "Failed"
      )
    }
    return response?.status === 200 ? true : false
  }
)
export const updateTaskTime = createAsyncThunk(
  "layout/updateTaskTime",
  async (attendance, { dispatch }) => {
    const response = await httpService(
      "POST",
      "/MyTasks/updatetasktime",
      attendance,
      ""
    )
    if (response?.status === 200) {
      taskHandler(dispatch, response?.data?.data)
      Toaster(
        "success",
        `Successfully ${attendance.type === "stop" ? " Stopped" : "Started"}`,
        "Success"
      )
    } else {
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const deleteTask = createAsyncThunk(
  "appTodo/deleteTask",
  async (params, { dispatch, getState }) => {
    const previousData = getState().myTasks.tasks
    const previousDataCopy = [...previousData]
    const fileIndex = previousData.findIndex(
      file => file.taskId === params.TaskId
    )
    previousDataCopy.splice(fileIndex, 1)
    const response = await httpService(
      "DELETE",
      "/TeamTasks/DeleteTask",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(reOrderTasks(previousDataCopy))
      dispatch(taskBoardTasks(null))
      if (params.subtask) {
        const { subtasksList } = getState().myTasks
        const updatedSubtaskList = subtasksList.filter(
          item => item.taskId !== params.TaskId
        )
        dispatch(updateDetailSubtaskList(updatedSubtaskList))
        dispatch(subtaskList(updatedSubtaskList))
      }
      Toaster("success", response?.data?.message, "Success")
    } else {
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const quickTaskUpdate = createAsyncThunk(
  "tasks/quickTaskUpdate",
  async (params, { dispatch }) => {
    dispatch(handleUpdateTaskQuicklyLoading(true))
    const response = await httpService(
      "PUT",
      "/TeamTasks/QuickUpdateTasks",
      params,
      ""
    )
    if (response?.status === 200) {
      dispatch(handleUpdateTaskQuicklyLoading(false))
      Toaster("success", `${response?.data?.message}`, "Success")
    } else {
      dispatch(handleUpdateTaskQuicklyLoading(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200
      ? response?.data?.userImagePath
        ? response?.data?.userImagePath
        : response
      : false
  }
)
export const quickTaskAdd = createAsyncThunk(
  "tasks/quickAddTask",
  async (params, { dispatch, getState }) => {
    const previousData = getState().myTasks.tasks
    const { searchFilter } = getState().myTasks
    dispatch(handleAddTaskLoadingStatus(true))
    const response = await httpService(
      "POST",
      "/TeamTasks/quickaddtask",
      params,
      ""
    )
    const userData = getDataFromLocalStorage("userDataAuth")

    if (response?.status === 200) {
      const updatedData = [response?.data?.data, ...previousData]
      dispatch(reOrderTasks(updatedData))
      if (AllowAddTaskInTBScreen(searchFilter, response.data.data, userData)) {
        dispatch(
          addTaskTaskBoard({
            index: params.index,
            data: response?.data?.data
          })
        )
      }
      Toaster("success", "Task Added Successfully", "Success")
      dispatch(handleAddTaskLoadingStatus(false))
    } else {
      Toaster("error", "something went wrong please try again.", "Failed")
      dispatch(handleAddTaskLoadingStatus(false))
    }
    return response?.status === 200 ? true : false
  }
)
export const getTemplateData = createAsyncThunk(
  "appTodo/getTemplateData",
  async (params, { dispatch }) => {
    dispatch(loadingTemplateData(true))
    const response = await httpService(
      "GET",
      "/Templates/templateslist",
      "",
      ""
    )
    if (response?.status === 200) {
      dispatch(loadingTemplateData(false))
      dispatch(templateData(response?.data?.data))
    } else {
      dispatch(loadingTemplateData(false))
      dispatch(templateData({}))
    }
  }
)
export const getPreviewTemplateData = createAsyncThunk(
  "appTodo/getPreviewTemplateData",
  async (params, { dispatch }) => {
    dispatch(loadingPreviewTemplateTasks(true))
    const response = await httpService(
      "GET",
      "/Templates/gettemplatestaskslist",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(loadingPreviewTemplateTasks(false))
      dispatch(handlepreviewTemplateData(response?.data?.data))
    } else {
      dispatch(loadingPreviewTemplateTasks(false))
      dispatch(handlepreviewTemplateData({}))
    }
  }
)
export const postTemplateData = createAsyncThunk(
  "appTodo/postTemplateData",
  async params => {
    const response = await httpService(
      "POST",
      "/Projects/addprojecttemplate",
      "",
      params
    )
    if (response?.status === 200) {
      Toaster("success", "Template is used", "Success")
    } else {
      Toaster("error", "something is going went wrong ", "Failed")
    }
  }
)

export const getSubtasks = createAsyncThunk(
  "TeamTasks/addtask",
  async (params, { dispatch, getState }) => {
    if (params.detailDrawer) dispatch(updateSubtaskLoading(true))
    const response = await httpService(
      "GET",
      getState().myTasks?.tasksUrl,
      "",
      params
    )
    if (response?.status === 200) {
      if (params?.detailDrawer) {
        dispatch(updateDetailSubtaskList(response?.data?.data))
        dispatch(updateSubtaskLoading(false))
      } else {
        dispatch(subtaskList(response?.data?.data))
      }
    } else {
      dispatch(subtaskList([]))
      if (params?.detailSubtaskList) dispatch(updateSubtaskLoading(false))
    }
    return response.status === 200 ? true : false
  }
)

export default appTodoSlice.reducer
