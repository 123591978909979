// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// custom Toaster
import Toaster from "@modules/Toaster"
import { getTasksrealTimeTableData } from "@src/views/admin/RealTime/store"

export const datatablesSlice = createSlice({
  name: "employeeData",
  initialState: {
    employeeData: [],
    employeesDDL: [],
    settingsData: [],
    getEditSetting: [],
    getEditEmployees: [],
    projectManagers: [],
    isLoading: false,
    loader: false,
    settingLoader: false,
    appLinkLoaderStatus: false,
    managerProjectLoader: false,
    projectDeleteLoader: false,
    projectData: [],
    teamData: [],
    projectLoader: false,
    teamLoader: false,
    employeeIdGenerator: [],
    isEditing: null,
    filters: { page: 1, pageSize: 1000 },
    AppKeyLoader: false
  },
  reducers: {
    teamLoader: (state, action) => {
      state.teamLoader = action.payload
    },
    projectLoader: (state, action) => {
      state.projectLoader = action.payload
    },
    projectData: (state, action) => {
      state.projectData = action.payload
    },
    teamData: (state, action) => {
      state.teamData = action.payload
    },
    projectDeleteLoader: (state, action) => {
      state.projectDeleteLoader = action.payload
    },
    managerProjectLoader: (state, action) => {
      state.managerProjectLoader = action.payload
    },
    projectManagers: (state, action) => {
      state.projectManagers = action.payload
    },
    employeesData: (state, action) => {
      state.employeeData = action.payload
    },
    employeeDDL: (state, action) => {
      state.employeesDDL = action.payload
    },
    settingsData: (state, action) => {
      state.settingsData = action.payload
    },
    getEditSettings: (state, action) => {
      state.getEditSetting = action.payload
    },
    getEditEmployees: (state, action) => {
      state.getEditEmployees = action.payload
    },
    loadingEmployees: (state, action) => {
      state.isLoading = action.payload
    },
    loadingStatus: (state, action) => {
      state.loader = action.payload
    },
    settingLoadingStatus: (state, action) => {
      state.settingLoader = action.payload
    },
    setAppLinkLoaderStatus: (state, action) => {
      state.appLinkLoaderStatus = action.payload
    },
    setemployeeIdGenerator: (state, action) => {
      state.employeeIdGenerator = action.payload
    },
    setEditFlag: (state, action) => {
      state.isEditing = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    SetAppKeyLoader: (state, action) => {
      state.AppKeyLoader = action.payload
    }
  }
})
export const {
  setAppLinkLoaderStatus,
  projectData,
  teamData,
  projectLoader,
  teamLoader,
  projectDeleteLoader,
  managerProjectLoader,
  projectManagers,
  employeesData,
  employeeDDL,
  settingsData,
  getEditSettings,
  settingLoadingStatus,
  getEditEmployees,
  loadingEmployees,
  loadingStatus,
  setemployeeIdGenerator,
  setEditFlag,
  setFilters,
  SetAppKeyLoader
} = datatablesSlice.actions
export default datatablesSlice.reducer

export const getData = createAsyncThunk(
  "datatables/getData",
  async (params, { dispatch }) => {
    dispatch(loadingEmployees(true))
    const response = await httpService("GET", "/Employees/empslist", "", params)
    if (response?.status === 200) {
      dispatch(loadingEmployees(false))
      dispatch(employeesData(response?.data?.data))
    } else {
      dispatch(employeesData([]))
      dispatch(loadingEmployees(false))
    }
    return response?.status === 200 ? true : false
  }
)
export const getEmployeesDDL = createAsyncThunk(
  "datatables/employeesDDL",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/Employees/addemployee",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(employeeDDL(response?.data))
    }
  }
)
export const editSetting = createAsyncThunk(
  "datatables/editSetting",
  async (params, { dispatch }) => {
    dispatch(settingLoadingStatus(true))
    const response = await httpService(
      "GET",
      "/Employees/addsettings",
      "",
      params
    )

    if (response.status === 200) {
      dispatch(getEditSettings(response?.data))
      dispatch(settingLoadingStatus(false))
    } else {
      dispatch(getEditSettings([]))
      dispatch(settingLoadingStatus(false))
    }
  }
)
export const forceLogout = createAsyncThunk(
  "datatables/forceLogout",
  async (params, { dispatch, getState }) => {
    const store = getState().realTimedataTables
    dispatch(settingLoadingStatus(true))
    const response = await httpService(
      "POST",
      "/Employees/CloseUserSession",
      "",
      params
    )

    if (response.status === 200) {
      if (store?.allData?.idleUsers) {
        const updatedIdleUsers = store?.allData?.idleUsers.filter(
          item => item.userId !== params.UserId
        )
        const updatedData = {
          ...store.allData,
          idleUsers: updatedIdleUsers,
          idleUsersCount: store?.allData?.idleUsersCount - 1
        }
        dispatch(getTasksrealTimeTableData(updatedData))
      }
      Toaster("success", response.data.message, "Success")
    } else {
      Toaster("error", response.data.message, "Failed")
    }
  }
)
export const postSettings = createAsyncThunk(
  "datatables/postSettings",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      "/Employees/addsettings",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      dispatch(settingsData(response?.data?.data))
      Toaster("success", "Settings Updated Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const getEditEmployee = createAsyncThunk(
  "datatables/getEditEmployee",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      `Employees/editemployee?uid=${params?.userId}&teammemberid=${params?.teamMemberId}`,
      "",
      ""
    )

    if (response.status === 200) {
      dispatch(getEditEmployees(response?.data))
      return true
    }
  }
)
export const getProjectManagers = createAsyncThunk(
  "datatables/getProjectManagers",
  async (param, { dispatch }) => {
    dispatch(managerProjectLoader(true))
    const response = await httpService("GET", `Employees/projectDDL`, "", param)
    if (response.status === 200) {
      dispatch(managerProjectLoader(false))
      dispatch(projectManagers(response?.data?.data))
      return true
    }
  }
)
export const getProjectTeams = createAsyncThunk(
  "datatables/getProjectTeams",
  async (param, { dispatch }) => {
    const response = await httpService("GET", `Employees/teamDDL`, "", param)
    if (response.status === 200) {
      dispatch(teamData(response?.data?.data))
      return true
    }
  }
)

export const deleteManagerProject = createAsyncThunk(
  "tagstables/deleteManagerProject",
  async (params, { dispatch, getState }) => {
    const { projects } = getState()?.EmployeeData?.getEditEmployees
    const prevProjects = [...projects]
    const updatedData = { ...getState()?.EmployeeData?.getEditEmployees }
    const { ProjectId, UserId, isManager } = params
    const param = { ProjectId, UserId }
    let response = []
    dispatch(projectDeleteLoader(true))

    if (isManager === true) {
      response = await httpService(
        "DELETE",
        `/Employees/RemoveManagerProject`,
        "",
        param
      )
    } else {
      response = await httpService(
        "POST",
        `/Projects/RemoveProjectEmployee`,
        "",
        param
      )
    }
    if (response?.status === 200) {
      dispatch(projectManagers(response?.data?.projectDDL))
      const index = prevProjects?.findIndex(
        project => project?.projectId === param?.ProjectId
      )
      index > -1 ? prevProjects.splice(index, 1) : ""
      dispatch(
        getEditEmployees({
          ...updatedData,
          projects: prevProjects
        })
      )
      Toaster("success", response?.data?.message, "Success")
      dispatch(projectDeleteLoader(false))
    } else {
      dispatch(projectDeleteLoader(false))
      Toaster("error", response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const deleteTeam = createAsyncThunk(
  "tagstables/deleteTeam",
  async (param, { dispatch, getState }) => {
    const prevProjects = [...getState()?.EmployeeData?.getEditEmployees?.teams]
    const updatedData = { ...getState()?.EmployeeData?.getEditEmployees }
    dispatch(projectDeleteLoader(true))
    const response = await httpService(
      "DELETE",
      `Employees/RemoveManagerTeam`,
      "",
      param
    )
    if (response?.status === 200) {
      const index = prevProjects?.findIndex(
        project => project?.teamId === param?.TeamId
      )
      index > -1 ? prevProjects.splice(index, 1) : ""
      dispatch(
        getEditEmployees({
          ...updatedData,
          teams: prevProjects
        })
      )
      Toaster("success", response?.data?.message, "Success")
      dispatch(projectDeleteLoader(false))
    } else {
      Toaster(
        "error",
        response?.data?.response?.data?.message ?? response.data?.message,
        "Failed"
      )
      dispatch(projectDeleteLoader(false))
    }
    return response?.status === 200 ? true : false
  }
)
export const postTeam = createAsyncThunk(
  "datatables/postTeam",
  async (params, { dispatch, getState }) => {
    const updatedData = { ...getState()?.EmployeeData?.getEditEmployees }
    dispatch(teamLoader(true))
    const response = await httpService("POST", "Employees/AddTeam", params, "")
    if (response.status === 200) {
      dispatch(
        getEditEmployees({
          ...updatedData,
          teams: [response?.data?.data, ...updatedData?.teams]
        })
      )
      dispatch(teamLoader(false))
      Toaster("success", response?.data?.message, "Success")
    } else {
      dispatch(teamLoader(false))
      Toaster("error", response?.data?.response?.data.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const postProject = createAsyncThunk(
  "datatables/postProject",
  async (params, { dispatch, getState }) => {
    const updatedData = { ...getState()?.EmployeeData?.getEditEmployees }
    dispatch(projectLoader(true))
    const response = await httpService(
      "POST",
      "Employees/AddProject",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(
        getEditEmployees({
          ...updatedData,
          projects: [response?.data?.data, ...updatedData?.projects]
        })
      )
      dispatch(projectLoader(false))
      Toaster("success", response?.data?.message, "Success")
    } else {
      dispatch(projectLoader(false))
      Toaster("error", response?.data?.response?.data.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const postEmployee = createAsyncThunk(
  "datatables/postEmployee",
  async (params, { dispatch, getState }) => {
    const updatedData = [...getState()?.EmployeeData?.employeeData]
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      "/Employees/addemployee",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(employeesData([...updatedData, response?.data?.data]))
      dispatch(loadingStatus(false))
      Toaster("success", "Employee added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.errors[0], "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const editEmployee = createAsyncThunk(
  "datatables/editEmployee",
  async (params, { getState, dispatch }) => {
    const prevData = [...getState()?.EmployeeData?.employeeData]
    const filters = getState()?.EmployeeData?.filters
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      "/Employees/editemployee",
      params,
      ""
    )
    if (response.status === 200) {
      const index = prevData?.findIndex(
        employee => employee?.userId === params?.userId
      )
      const shouldRemove =
        (filters.status && params.isActive !== prevData[index].isActive) ||
        (filters.teamId && params.teamId !== prevData[index].teamId) ||
        (filters.role && params.role !== prevData[index].role)
      if (index >= 0) {
        if (shouldRemove) {
          prevData.splice(index, 1)
        } else {
          prevData[index] = response?.data?.data
        }
      }
      dispatch(employeesData(prevData))
      dispatch(loadingStatus(false))
      dispatch(setEditFlag(false))
      Toaster("success", "Employee Edit Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
  }
)
export const editEmployeeEmail = createAsyncThunk(
  "teamstable/EditEmail",
  async (param, { getState, dispatch }) => {
    const prevData = [...getState()?.EmployeeData?.employeeData]
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      `/Employees/EditEmail`,
      "",
      param
    )
    if (response?.status === 200) {
      dispatch(loadingStatus(false))
      const index = prevData?.findIndex(
        employee => employee?.userId === param?.UserId
      )
      prevData.splice(index, 1, { ...prevData[index], email: param.email })
      dispatch(employeesData(prevData))
      Toaster("success", "Employee email updated successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const reSendInvitaion = createAsyncThunk(
  "datatables/reSendInvitaion",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "PUT",
      `/Employees/ReSendInvitaion/${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      Toaster("success", "Invitation Sent Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
  }
)

export const appLink = createAsyncThunk(
  "datatables/appLink",
  async (params, { dispatch }) => {
    dispatch(setAppLinkLoaderStatus(true))
    const response = await httpService(
      "PUT",
      `/Employees/SendDesktopInvitaion/${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(setAppLinkLoaderStatus(false))
      Toaster("success", "Link Send Successfully", "Success")
    } else {
      dispatch(setAppLinkLoaderStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
  }
)
export const deactivateEmployee = createAsyncThunk(
  "datatables/reSendInvitaion",
  async (params, { dispatch, getState }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      `/Employees/reassignusertasks`,
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      const { employeeData } = getState()?.EmployeeData
      const updatedData = employeeData?.filter(
        item => item?.userId !== params?.userId
      )
      dispatch(employeesData(updatedData))
      Toaster("success", response?.data?.message, "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.message, "Failed")
    }
  }
)
export const GenerateEmployeeid = createAsyncThunk(
  "generate/employeeId",
  async (params, { dispatch }) => {
    dispatch(SetAppKeyLoader(true))
    const response = await httpService(
      "GET",
      `Employees/GetEmployeeToken?UserId=${params}`,
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(SetAppKeyLoader(false))
      dispatch(setemployeeIdGenerator(response?.data?.data))
    } else {
      dispatch(SetAppKeyLoader(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
  }
)
