// ** Dependencies Imports
import { User } from "react-feather"
import { UncontrolledTooltip } from "reactstrap"
import { components } from "react-select"
// ** Custom Components
import { addTaskObj } from "./components/PredifneObjects"
import Avatar from "@components/avatar"
// ** Utils
import { isObjEmpty, getDataFromLocalStorage } from "@utils"
// ** store Imports
import {
  addTask,
  getEditTask,
  getTaskAttachmentsById,
  getTaskDetailsById,
  getTaskPlannedDuration,
  getTasks,
  handleTaskTitle,
  updateTask,
  uploadFiles
} from "./store"
import { formatDate, addDurationInDate } from "@dates"
import { setIsTaskUpdated } from "./components/MainModals/store"
import { updateAllTasksCount } from "../../admin/Home/Store"

export const fetchIdofLoginUser = () => {
  const UserDataId = getDataFromLocalStorage("userDataAuth")
  return UserDataId
}
export const filterDDLById = (
  arr = [],
  id,
  valueKey = "value",
  textKey = "text"
) => {
  let arrCopy = []
  arrCopy = arr.map(item => ({
    value: item[valueKey],
    label: item[textKey]
  }))
  const [_obj] = arrCopy?.filter(item => {
    return String(item?.value) === String(id)
  })
  return _obj
}
// ** Function Sort Array by date
export const sortArrByDate = (arr, date) => {
  const arrCopy = [...arr]
  arrCopy.sort(function (x, y) {
    const a = new Date(x[date]),
      b = new Date(y[date])
    return b - a
  })
  return arrCopy
}
export const onScroll = async (
  alreadyRequestSent,
  currentPage,
  listInnerRef,
  hasNextPage,
  filterParams,
  taskStatus,
  setPageNumber,
  pageNumber,
  dispatch,
  searchByKey,
  duestatus
) => {
  if (listInnerRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      if (
        hasNextPage === true &&
        currentPage !== pageNumber &&
        alreadyRequestSent === false
      ) {
        const response = await dispatch(
          getTasks({
            ...filterParams,
            status: taskStatus,
            duestatus: filterParams?.duestatus
              ? filterParams?.duestatus
              : duestatus
              ? duestatus
              : "",
            pageno: pageNumber,
            projectid: filterParams?.projectid,
            teamid: filterParams?.teamid,
            userid: filterParams?.userid,
            fromduedate: filterParams?.dueDate?.start,
            toduedate: filterParams?.dueDate?.end,
            viewtype: "List",
            pagesize: 20,
            searchbydatetype: searchByKey
          })
        )
        response.payload === true ? await setPageNumber(pageNumber + 1) : ""
      }
    }
  }
}
export const renderUserSelfCreated = (isSelfCreated, taskId) => {
  return (
    <>
      {isSelfCreated === true && (
        <>
          <User id={`renderUserSelfCreated${taskId}`} size={14} />
          <UncontrolledTooltip target={`renderUserSelfCreated${taskId}`}>
            Self Created
          </UncontrolledTooltip>
        </>
      )}
    </>
  )
}
// ** Function to selectTask on click
export const handleTaskClick = (obj, handleTaskSidebar, dispatch, value) => {
  const { projectId, taskId, userId, plannedEndDate } = obj
  dispatch(handleTaskTitle(value))
  dispatch(getEditTask({ projectId, id: taskId }))
  dispatch(
    getTaskPlannedDuration({
      UserId: userId,
      PlannedDate: formatDate(plannedEndDate, "YYYY-MM-DD")
    })
  )
  dispatch(getTaskAttachmentsById({ taskId, projectId }))
  handleTaskSidebar()
}

export const handleTaskDetailSideBarOpen = (
  dispatch,
  handleDetailSidebar,
  taskId
) => {
  handleDetailSidebar()
  dispatch(
    getTaskDetailsById({
      id: taskId
    })
  )
}

export const formatDuration = duration => {
  const exactValue = duration?.split(":")

  return `${
    exactValue && exactValue[0] !== undefined
      ? Math.floor(exactValue[0]) < 10 && Math.floor(exactValue[0]) >= 0
        ? `0${Math.floor(exactValue[0])}`
        : Math.floor(exactValue[0])
      : "00"
  }:${
    exactValue && exactValue[1] !== undefined
      ? Math.floor(exactValue[1]) < 10 && Math.floor(exactValue[1]) >= 0
        ? `0${Math.floor(exactValue[1])}`
        : Math.floor(exactValue[1])
      : "00"
  }`
}

export const onTaskSubmit = async (
  submitButtonType,
  selectedTask,
  data,
  recurrenceInfoData,
  files,
  checklistData,
  handleTaskSidebar,
  handleReset,
  handleTitleReset,
  dispatch,
  taskTitle,
  tour
) => {
  const {
    title,
    description,
    tags = [],
    taskPriority,
    taskProject,
    taskStatus,
    taskEmployee,
    plannedDuration,
    dueDate,
    comments,
    remindMe,
    taskGroup,
    isVerified
  } = data
  const userDataId = fetchIdofLoginUser()
  const reStructuredUserIds = arr => {
    const newArr = []
    arr.map(item => {
      newArr.push(Number(item.value))
    })
    return newArr
  }
  let tagsList = tags || []
  tagsList = tagsList?.map(tag => tag?.value)
  addTaskObj.taskName = title
  addTaskObj.description = description
  addTaskObj.tags = tagsList?.toString()
  addTaskObj.plannedEndDate = formatDate(
    addDurationInDate(dueDate, plannedDuration),
    "YYYY-MM-DDTHH:mm:ss",
    true
  )
  addTaskObj.plannedStartDate = formatDate(dueDate, "YYYY-MM-DDTHH:mm:ss", true)
  addTaskObj.projectId = Number(taskProject?.value)
  addTaskObj.status = taskStatus?.value
  addTaskObj.plannedDuration = plannedDuration
  addTaskObj.strPriority = taskPriority?.value
  addTaskObj.comments = isObjEmpty(selectedTask) ? "" : comments
  addTaskObj.remindMe = Number(remindMe)
  addTaskObj.taskType = Number(taskGroup?.value)
  addTaskObj.userId = Array.isArray(taskEmployee)
    ? null
    : Number(taskEmployee?.value)
  addTaskObj.userIds = Array.isArray(taskEmployee)
    ? reStructuredUserIds(taskEmployee)
    : []
  addTaskObj.vmProjectTasksChecklists = checklistData ? checklistData : []
  addTaskObj.vMRecurrence =
    recurrenceInfoData?.reccheckbox === "true" ? recurrenceInfoData : {}
  addTaskObj.files = files?.map(file => file.filePath)
  addTaskObj.isVerifiedByManager = isVerified
  const payload = addTaskObj
  if (taskTitle === "Edit") {
    const response = await dispatch(
      updateTask({ ...payload, taskId: selectedTask.taskId })
    )
    response?.payload
      ? (handleTaskSidebar(), handleReset(), dispatch(uploadFiles([])))
      : ""
  } else {
    const response = await dispatch(addTask(payload))
    tour?.isActive() ? tour?.next() : ""
    response?.payload?.data
      ? submitButtonType === "addAndClose"
        ? (handleReset(),
          handleTitleReset(),
          handleTaskSidebar(),
          dispatch(uploadFiles([])),
          window.location.pathname === "/tasks"
            ? dispatch(setIsTaskUpdated(true))
            : "")
        : (handleTitleReset(),
          dispatch(uploadFiles([])),
          dispatch(
            getTaskPlannedDuration({
              UserId: Array.isArray(taskEmployee)
                ? taskEmployee[0].value
                : taskEmployee?.value,
              PlannedDate: formatDate(dueDate, "YYYY-MM-DD")
            })
          ),
          window.location.pathname === "/tasks"
            ? dispatch(setIsTaskUpdated(true))
            : "")
      : ""
    if (response?.payload?.data[0]?.userId === userDataId?.userOrgs[0].userId) {
      const plannedStartDate = response?.payload?.data[0]?.plannedStartDate
      const statusType =
        response?.payload?.data[0]?.status === 1
          ? "todoTasks"
          : response?.payload?.data[0]?.status === 2
          ? "onHoldTasks"
          : response?.payload?.data[0]?.status === 3
          ? "inProgressTasks"
          : response?.payload?.data[0]?.status === 4
          ? "completedTasks"
          : null
      dispatch(
        updateAllTasksCount({
          statustype: statusType,
          plannedstartDate: plannedStartDate
        })
      )
    }
  }
}
export const renderDDL = (
  arr,
  valueKey = "value",
  textKey = "text",
  img = "imagePath"
) => {
  const reStructuredArr = arr?.map(obj => ({
    ...obj,
    value: obj[valueKey],
    label: obj[textKey],
    [img]: obj[img]
  }))
  return reStructuredArr
}
export const handleFilterVisibility = value => {
  const filterIcon = document.getElementById("filterIcon")
  if (filterIcon) {
    filterIcon.style.visibility = value
  }
}

export const convertTimeDurationIntoHours = value => {
  const convertedValue =
    value?.split(":").reduce(function (hours, minutes) {
      const valueAdded = parseFloat(hours) + minutes
      return valueAdded / 60
    }) * 60

  return Math.ceil(convertedValue)
}

export const sumTimeDuration = (time1, time2) => {
  if (!time1 || !time2) {
    return "00:00"
  }
  const [time1Hours, time1Minutes] = time1.split(":").map(Number)
  const [time2Hours, time2Minutes] = time2.split(":").map(Number)

  const time1Min = time1Hours * 60
  const totalMinutesAndHours1 = time1Min + time1Minutes

  const time2Min = time2Hours * 60
  const totalMinutesAndHours2 = time2Min + time2Minutes

  const totalMinutes = totalMinutesAndHours1 + totalMinutesAndHours2

  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  const result = `${hours}:${minutes.toString().padStart(2, "0")}`

  return result
}

export const convertHoursToDuration = value => {
  if (value) {
    let hours = 0
    let minutes = 0
    hours = Math.floor(value / 60)
    minutes = value % 60
    return `${hours > 9 ? hours : `0${hours}`}:${
      minutes > 9 ? minutes : `0${minutes}`
    }`
  } else {
    return "00:00"
  }
}

export const getSrcDestIndex = (action, oldTask, newTask, newState) => {
  let srcIndex
  let destIndex
  if (action.payload.groupby === "status") {
    srcIndex = newState.groups.findIndex(item => item.apiKey === oldTask.status)
    destIndex = newState.groups.findIndex(
      item => item.apiKey === newTask.status
    )
  } else if (action.payload.groupby === "strPriority") {
    srcIndex = newState.groups.findIndex(
      item => item.apiKey === oldTask.strPriority
    )
    destIndex = newState.groups.findIndex(
      item => item.apiKey === newTask.strPriority
    )
  } else if (action.payload.groupby === "taskType") {
    srcIndex = newState.groups.findIndex(
      item => item.apiKey === oldTask.taskType
    )
    destIndex = newState.groups.findIndex(
      item => item.apiKey === newTask.taskType
    )
  }
  return [srcIndex, destIndex]
}

export const getUpdatedGroupState = (
  srcIndex,
  destIndex,
  newState,
  newTask,
  oldTask
) => {
  let updatedSrcGroup = {
    ...newState.groups[srcIndex]
  }
  const srcTaskIds = updatedSrcGroup?.taskIds?.filter(
    item => item !== oldTask.taskId
  )
  updatedSrcGroup = {
    ...updatedSrcGroup,
    taskIds: srcTaskIds,
    count: updatedSrcGroup.count - 1
  }
  let updatedDestGroup = {
    ...newState.groups[destIndex]
  }
  const desTaskIds = [...updatedDestGroup.taskIds]
  desTaskIds.unshift(newTask.taskId)
  updatedDestGroup = {
    ...updatedDestGroup,
    taskIds: desTaskIds,
    count: updatedDestGroup.count ? updatedDestGroup.count + 1 : 1
  }

  const updatedGroups = newState.groups.map(item => {
    if (item.apiKey === updatedSrcGroup.apiKey) {
      return updatedSrcGroup
    } else if (item.apiKey === updatedDestGroup.apiKey) {
      return updatedDestGroup
    } else {
      return item
    }
  })

  const finalData = {
    ...newState,
    groups: updatedGroups
  }
  return finalData
}

export const renderColor = () => {
  const randomValue = Math.ceil(Math.random() * 6)
  switch (randomValue) {
    case 1:
      return "light-primary"
    case 2:
      return "light-secondary"
    case 3:
      return "light-success"
    case 5:
      return "light-danger"
    case 6:
      return "light-warning"
    case 7:
      return "light-info"

    default:
      return "light-primary"
  }
}

export const UsersComponent = ({ data, ...props }) => {
  const [firstObj] = data.label.split("-")
  return (
    <components.Option {...props}>
      <div className="d-flex flex-wrap align-items-center">
        {data?.imagePath ? (
          <Avatar
            className="my-0 me-1"
            size="sm"
            content={firstObj
              ?.split(" ")
              ?.slice(0, 2)
              ?.join(" ")
              ?.toUpperCase()}
            // initials={true}
            img={data?.imagePath}
            color={renderColor()}
          />
        ) : (
          <Avatar
            className="my-0 me-1"
            size="sm"
            content={firstObj
              ?.split(" ")
              ?.slice(0, 2)
              ?.join(" ")
              ?.toUpperCase()}
            initials
            color={renderColor()}
          />
        )}
        <div>{data?.label}</div>
      </div>
    </components.Option>
  )
}

export const AllowAddTaskInTBScreen = (searchFilter, task, userData) => {
  if (searchFilter.myTasks && task.userId === userData.id) {
    if (
      searchFilter?.projectId === task.projectId ||
      searchFilter?.projectId === null
    ) {
      return true
    }
  } else if (
    (searchFilter?.employee === null ||
      searchFilter?.employee === task.userId) &&
    (searchFilter?.projectId === null ||
      searchFilter?.projectId === task.projectId) &&
    searchFilter?.myTasks === false
  ) {
    return true
  }
  return false
}
export const taskList = [
  { id: 1, label: "name", name: "Name", default: true },
  {
    id: 2,
    label: "projectName",
    name: "Project Name",
    default: true
  },
  { id: 3, label: "taskName", name: "Task Name", default: true },
  {
    id: 4,
    label: "strPriority",
    name: "Priority",
    default: false
  },
  {
    id: 5,
    label: "taskTotalTime",
    name: "Total Worked Time",
    default: false
  },
  {
    id: 6,
    label: "lastWorkDate",
    name: "Last Worked Date",
    default: false
  },
  {
    id: 7,
    label: "plannedEndDate",
    name: "Planned End Date",
    default: false
  },
  {
    id: 8,
    label: "plannedDuration",
    name: "Planned Time",
    default: false
  }
]
export const csvColumns = [
  { id: 1, label: "create_By_Name", name: "Assigned By", default: false },
  {
    id: 2,
    label: "description",
    name: "Description",
    default: false
  },
  { id: 3, label: "email", name: "Email", default: false },
  { id: 4, label: "is_Self_Created", name: "is Self Created", default: false },
  {
    id: 5,
    label: "last_Work_Date",
    name: "Last Worked Date",
    default: false
  },
  {
    id: 6,
    label: "name",
    name: "Employee",
    default: false
  },
  {
    id: 7,
    label: "planned_Start_Date",
    name: "Planned Start Date",
    default: false
  },
  {
    id: 8,
    label: "planned_End_Date",
    name: "Planned End Date",
    default: false
  },
  {
    id: 9,
    label: "planned_Duration",
    name: "Planned Duration",
    default: false
  },
  {
    id: 10,
    label: "priority",
    name: "Priority",
    default: false
  },
  {
    id: 11,
    label: "project_Name",
    name: "Project",
    default: true
  },
  {
    id: 12,
    label: "status",
    name: "Status",
    default: false
  },
  {
    id: 13,
    label: "tags",
    name: "Tags",
    default: false
  },
  {
    id: 14,
    label: "task_Name",
    name: "Task Name",
    default: true
  },
  {
    id: 15,
    label: "task_Total_Time",
    name: "Task Total Time",
    default: false
  },
  {
    id: 16,
    label: "task_Type",
    name: "Task Type",
    default: false
  },
  {
    id: 17,
    label: "team_Name",
    name: "Team",
    default: false
  }
]
export const getColor = value => {
  let color
  switch (value) {
    case 1:
      color = "bg-danger"
      break
    case 2:
      color = "bg-warning"
      break
    case 3:
      color = "bg-inProgress"
      break
    case 4:
      color = "bg-success"
      break
  }
  return color
}
//to assign items group value dynamically
export const groupKeyCheck = value => {
  const object = {
    status: "status",
    project: "projectName",
    taskType: "taskType",
    employee: "userId",
    priority: "strPriority",
    tasks: "taskName"
  }
  return object[value]
}
export const Years = [
  1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992,
  1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005,
  2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
  2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031,
  2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044,
  2045, 2046, 2047, 2048, 2049, 2050
]
export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]
