// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig"
const CryptoJS = require("crypto-js")

const decryptData = data => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SOCIAL_SECRET_KEY)
  const iv = CryptoJS.enc.Utf8.parse("00000000000000000000000000000000")
  const encrypt = CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return JSON.parse(encrypt.toString(CryptoJS.enc.Utf8)) || {}
}

const getDataFromLocalStorage = data => {
  const userData = localStorage.getItem(data)
  let decrypted
  if (userData) {
    decrypted = decryptData(userData)
  }
  return decrypted
}
const encrypData = data => {
  const encryptedMessage = {}

  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SOCIAL_SECRET_KEY)
  const iv = CryptoJS.enc.Utf8.parse("00000000000000000000000000000000")

  const encrypt = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  encryptedMessage.iv = iv
  encryptedMessage.token = encrypt.ciphertext.toString(CryptoJS.enc.Base64)
  return encryptedMessage
}
const setDataInLocalStorage = (data, name) => {
  const encrypted = encrypData(data)
  localStorage.setItem(name, encrypted.token)
}

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    skin: themeConfig.layout.isRTL,
    isRTL: themeConfig.layout.isRTL,
    layout: themeConfig.layout.type,
    lastLayout: themeConfig.layout.type,
    footerType: themeConfig.layout.footer.type,
    navbarType: themeConfig.layout.navbar.type,
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth,
    menuCollapsed: themeConfig.layout.menu.isCollapsed,
    routerTransition: themeConfig.layout.routerTransition,
    navbarColor: themeConfig.layout.navbar.backgroundColor
  },
  reducers: {
    handleRTL: (state, action) => {
      state.isRTL = action.payload
      window.localStorage.setItem("direction", JSON.stringify(action.payload))
    },
    handleSkin: (state, action) => {
      const userData = getDataFromLocalStorage("userDataAuth")
      state.skin = action.payload
      window.localStorage.setItem("skin", JSON.stringify(action.payload))
      if (userData) {
        setDataInLocalStorage(
          JSON.stringify({ ...userData, displayMode: action.payload }),
          "userDataAuth"
        )
      }
    },
    handleLayout: (state, action) => {
      state.layout = action.payload
    },
    handleFooterType: (state, action) => {
      state.footerType = action.payload
    },
    handleNavbarType: (state, action) => {
      state.navbarType = action.payload
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload
    },
    handleLastLayout: (state, action) => {
      state.lastLayout = action.payload
    },
    handleNavbarColor: (state, action) => {
      state.navbarColor = action.payload
    },
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload
    },
    handleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload
      window.localStorage.setItem(
        "menuCollapsed",
        JSON.stringify(action.payload)
      )
    },
    handleRouterTransition: (state, action) => {
      state.routerTransition = action.payload
    }
  }
})
export const {
  handleRTL,
  handleSkin,
  handleLayout,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handleFooterType,
  handleNavbarColor,
  handleContentWidth,
  handleMenuCollapsed,
  handleRouterTransition
} = layoutSlice.actions
export default layoutSlice.reducer
