// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// custom Toaster
import Toaster from "@modules/Toaster"
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notificationLists: [],
    announcementList: [],
    taskDetails: {},
    inviteUsersLoader: false,
    inviteUsersErrors: [],
    totalCount: 0,
    unread: 0,
    taskLoading: false,
    displayDarkOverlay: false,
    tourLevel: "completed",
    updatedTourStep: false,
    alreadyCompleted: true,
    popoverClose: true,
    modelKey: false
  },
  reducers: {
    modelOpen: (state, action) => {
      state.modelKey = action.payload
    },
    inviteUsersErrors: (state, action) => {
      state.inviteUsersErrors = action.payload
    },
    notificationList: (state, action) => {
      state.notificationLists = action.payload
    },
    announcementList: (state, action) => {
      state.announcementList = action.payload
    },
    updateAnnouncementList: (state, action) => {
      state.announcementList = [...state.announcementList, action.payload]
    },
    getTaskDetail: (state, action) => {
      state.taskDetails = action.payload
    },
    inviteUsersLoader: (state, action) => {
      state.inviteUsersLoader = action.payload
    },
    updateTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    updateUnreadCount: (state, action) => {
      state.unread = action.payload
    },
    getTaskLoading: (state, action) => {
      state.taskLoading = action.payload
    },
    updateDarkOverlay: (state, action) => {
      state.displayDarkOverlay = action.payload
    },
    updateLevelAndSublevel: (state, action) => {
      state.tourLevel = action.payload?.tourLvl
    },
    updateTourStep: (state, action) => {
      state.updatedTourStep = action.payload
    },
    updateAlreadyCompleted: (state, action) => {
      state.alreadyCompleted = action.payload
    },
    setpopoverClose: (state, action) => {
      state.popoverClose = action.payload
    }
  }
})

export const {
  modelOpen,
  notificationList,
  announcementList,
  updateAnnouncementList,
  getTaskDetail,
  inviteUsersLoader,
  inviteUsersErrors,
  updateTotalCount,
  updateUnreadCount,
  getTaskLoading,
  updateDarkOverlay,
  updateLevelAndSublevel,
  updateTourStep,
  updateAlreadyCompleted,
  setpopoverClose
} = notificationsSlice.actions
export default notificationsSlice.reducer

export const getNotificationList = createAsyncThunk(
  "notifications/getData",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "GET",
      "/MyTasks/getnotifications",
      "",
      params
    )
    if (response.status === 200) {
      if (params.pageno > 1) {
        const { notificationLists } = getState()?.Notifications
        dispatch(
          notificationList([...notificationLists, ...response?.data?.data])
        )
        dispatch(updateUnreadCount(response?.data?.totalUnread))
      } else {
        dispatch(notificationList(response?.data?.data))
        dispatch(updateTotalCount(response?.data?.totalItemCount))
        dispatch(updateUnreadCount(response?.data?.totalUnread))
      }
    }
  }
)
export const getAnnouncementList = createAsyncThunk(
  "announcements/getData",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      `/Users/GetAnnouncement/${params.userId}?TenantId=${params.tenantId}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(announcementList(response?.data?.data))
    }
  }
)
export const dismissAnnouncementList = createAsyncThunk(
  "announcements/dismissData",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "PUT",
      `/Users/DismissAnnouncement`,
      params,
      ""
    )
    if (response.status === 200) {
      const announcementLists = getState()?.Notifications?.announcementList
      const newAnnouncements = announcementLists.filter(
        noti => !params?.announcementsId.includes(noti.announcementID)
      )
      dispatch(announcementList(newAnnouncements))
    } else {
      Toaster("error", "This Annoucement cannot be dismissed", "Failed")
    }
  }
)
export const updateDisplayMode = createAsyncThunk(
  "notifications/getData",
  async params => {
    const response = await httpService(
      "GET",
      "/Users/changedisplaymode",
      "",
      params
    )
    if (response.status === 200) {
      return true
    }
  }
)
export const getTaskDetails = createAsyncThunk(
  "notifications/getTaskDetails",
  async (params, { dispatch }) => {
    dispatch(getTaskLoading(true))
    const response = await httpService("GET", "/MyTasks/taskdetail", "", params)
    if (response.status === 200) {
      dispatch(getTaskDetail(response?.data?.data))
      dispatch(getTaskLoading(false))
      return {
        success: true,
        data: response?.data?.data !== null ? response?.data?.data : {}
      }
    } else {
      dispatch(getTaskDetail({}))
      dispatch(getTaskLoading(false))
      return { success: true, data: {} }
    }
  }
)
export const readSingleNotification = createAsyncThunk(
  "notifications/notificationList",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "PUT",
      `/MyTasks/marknotificationascomplete?NotificationId=${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      const { notificationLists, unread } = getState().Notifications
      const updatedNotifications = notificationLists.map(item => {
        return item.recNo === params ? { ...item, isViewed: true } : item
      })
      dispatch(notificationList(updatedNotifications))
      dispatch(updateUnreadCount(unread - 1))
      return {
        success: true,
        data: response?.data?.data !== null ? response?.data?.data : {}
      }
    } else {
      return { success: true, data: {} }
    }
  }
)

export const readAllNotifications = createAsyncThunk(
  "notifications/notificationList",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "PUT",
      `/MyTasks/markallnotificationsascomplete`,
      "",
      ""
    )
    if (response.status === 200) {
      const notificationLists = getState().Notifications.notificationLists
      const updatedNotifications = notificationLists.map(item => {
        return { ...item, isViewed: true }
      })
      dispatch(notificationList(updatedNotifications))
      dispatch(updateUnreadCount(0))
      return {
        success: true,
        data: response?.data?.data !== null ? response?.data?.data : {}
      }
    } else {
      return { success: true, data: {} }
    }
  }
)
export const inviteUsers = createAsyncThunk(
  "notifications/inviteUsers",
  async (params, { dispatch }) => {
    dispatch(inviteUsersLoader(true))
    dispatch(inviteUsersErrors([]))
    const response = await httpService(
      "POST",
      `/Employees/addbulkemployee`,
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(inviteUsersLoader(false))
      dispatch(inviteUsersErrors([]))
      Toaster("success", "Invitation sent successfully", "Success")
      return true
    } else {
      dispatch(inviteUsersLoader(false))
      dispatch(inviteUsersErrors(response?.data?.response?.data?.data))
      Toaster("error", "something went wrong", "Failed")
      return false
    }
  }
)

export const updateTaskLvl = createAsyncThunk(
  "notifications/inviteUsers",
  async (params, { dispatch }) => {
    const response = await httpService(
      "PUT",
      `/Users/UpdateTourSeqLvl`,
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(updateLevelAndSublevel({ tourLvl: params?.stepTitle }))
      return true
    } else {
      return false
    }
  }
)
export const getTourSeqLvl = createAsyncThunk(
  "notifications/inviteUsers",
  async (params, { dispatch }) => {
    const response = await httpService("GET", `/Users/GetTourSeqLvl`, "", "")
    if (response.status === 200) {
      if (response?.data?.stepTitle !== "completed") {
        dispatch(updateAlreadyCompleted(false))
      }
      dispatch(updateLevelAndSublevel({ tourLvl: response?.data?.stepTitle }))
    }
    return response
  }
)
