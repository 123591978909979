// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"

export const FAQ = createSlice({
  name: "FAQ",
  initialState: {
    Loading: false,
    faqResponse: []
  },
  reducers: {
    loadingStatus: (state, action) => {
      state.Loading = action.payload
    },
    faqResponseData: (state, action) => {
      state.faqResponse = action.payload
    }
  }
})

export const { loadingStatus, faqResponseData } = FAQ.actions
export default FAQ.reducer

export const getFaqData = createAsyncThunk(
  "FAQ/FAQDATA",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("GET", "Faqs/faqslist", "", params)
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      dispatch(faqResponseData(response?.data?.data))
    } else {
      dispatch(loadingStatus(false))
      dispatch(faqResponseData([]))
    }
  }
)
