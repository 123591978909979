// You can customize the template with the help of this file
//Template config options
const themeConfig = {
  app: {
    appName: "Sticky Tasks",
    appLogoImage: `${process.env.REACT_APP_ASSETS_URL}/logo/navLogo.png`,
    appMainLogoImage: `${process.env.REACT_APP_ASSETS_URL}/logo/logo.png`,
    appSocialGoogleImage: `${process.env.REACT_APP_ASSETS_URL}/logo/icons-google.png`,
    appSocialMicrosoftImage: `${process.env.REACT_APP_ASSETS_URL}/logo/icons-microsoft.png`
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: "vertical", // vertical, horizontal
    contentWidth: "boxed", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: true
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "sticky", // static , sticky , floating, hidden
      backgroundColor: "warning" // BS color options [primary, success, etc]
    },
    footer: {
      type: "static" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}
export default themeConfig
