// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// ** Dependencies Imports
export const realTimedataTables = createSlice({
  name: "realTimedataTables",
  initialState: {
    allData: [],
    taskLoading: false
  },
  reducers: {
    getTasksrealTimeTableData: (state, action) => {
      state.allData = action.payload
    },
    loadingStatus: (state, action) => {
      state.taskLoading = action.payload
    }
  }
})
export const { getTasksrealTimeTableData, loadingStatus } =
  realTimedataTables.actions
export const getData = createAsyncThunk(
  "ReaTime/getData",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "GET",
      `RealTime/teamworkstatus?status=${params?.status}`,
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getTasksrealTimeTableData(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(getTasksrealTimeTableData([]))
      dispatch(loadingStatus(false))
    }
  }
)
export default realTimedataTables.reducer
